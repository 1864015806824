import { useAppSelector } from '../redux/hooks';

function fNano(v: number) {
  return `${(v / 1000000).toFixed(2)} ms`;
}

interface Telemetry {
  nanoseconds_elapsed_total: number;
  nanoseconds_elapsed_hot: number;
  nanoseconds_elapsed_cold: number;
}
function hasTelemetry(view: unknown): view is { telemetry: Telemetry } {
  return (view as { telemetry: Telemetry }).telemetry !== undefined;
}

export default function Telemetry() {
  const { singleView } = useAppSelector((state) => state.continuous);

  // We need to do these type checks due to these fields being only added to the cloud
  if (singleView.profile && hasTelemetry(singleView.profile)) {
    const t = singleView.profile.telemetry;

    let msg = `query elapsed time: ${fNano(t.nanoseconds_elapsed_total)}`;
    msg += `    hot: ${fNano(t.nanoseconds_elapsed_hot)}`;
    msg += `    cold: ${fNano(t.nanoseconds_elapsed_cold)}`;
    return (
      <code>
        <pre>{msg}</pre>
      </code>
    );
  }

  return <></>;
}
