import { z } from 'zod';

import { planModel } from './plans';

export const organizationModel = z.object({
  id: z.string(),
  name: z.string(),
  displayName: z.string(),
});

export const fullOrganizationModel = z.object({
  id: z.string(),
  name: z.string(),
  displayName: z.string(),
  authenticationDomains: z.optional(z.array(z.string())),
  customTerms: z.boolean(),
  hideCreditCardButton: z.boolean(),
  forbidSwitchingPlans: z.boolean(),
  throttlingPlan: planModel,
  throttlingStatus: z.enum(['ok', 'warning', 'throttled']),
});

export const organizationsModel = z.array(organizationModel);

export type Organizations = z.infer<typeof organizationsModel>;
export type Organization = z.infer<typeof fullOrganizationModel>;
