import { Result } from '@webapp/util/fp';
import type { ZodError } from 'zod';
import { request, parseResponse } from '@webapp/services/base';
import type { RequestError } from '@webapp/services/base';
import { type Payments, paymentsModel } from '../models/payments';

export function goToStripeManage() {
  window.open('/api/payments/manage');
}
export function goToStripeSetup() {
  window.open('/api/payments/setup');
}

export async function loadPayments(): Promise<
  Result<Payments, RequestError | ZodError>
> {
  const response = await request(`/api/payments/status`);
  if (response.isOk) {
    return parseResponse(response, paymentsModel);
  }

  return Result.err<Payments, RequestError>(response.error);
}
