import { useEffect, useMemo } from 'react';
import { Event } from '@cloudstorage/models/ci';
import { Maybe } from '@webapp/util/fp';
import { randomId } from '@webapp/util/randomId';
import ReactFlot from 'react-flot';

interface TimelineProps {
  onSelectedCIEvent: (e: Event) => void;
  onTimeRangeChanged: (from: number, to: number) => void;
  ciEvents: Event[];
  from: Date;
  to: Date;
}
export function Timeline(props: TimelineProps) {
  const { ciEvents, onSelectedCIEvent, onTimeRangeChanged, from, to } = props;
  const id = useMemo(() => {
    return randomId('timeline');
  }, []);
  const idSelector = `#${id}`;

  // Create dummy datapoints for from and to, so that the timeline corresponds
  // To that set interval
  const timeline = [
    [from, undefined],
    [to, undefined],
    ...ciEvents.map((v) => ciEventToTimeline(v)),
  ];

  // TODO: two way binding
  // so that selecting via the table is reflected in the timelien too
  useEffect(() => {
    $(idSelector).bind(
      'plotclick',
      function (event, pos, item: { datapoint: [number, number] }) {
        if (item) {
          const found = findClickedItem(
            ciEvents,
            item.datapoint[0],
            item.datapoint[1]
          );

          if (found.isJust) {
            onSelectedCIEvent(found.value);
          }
        }
      }
    );

    return () => {
      $(idSelector).unbind('plotclick');
    };
  }, [ciEvents]);

  useEffect(() => {
    $(idSelector).bind(
      'plotselected',
      (event: unknown, ranges: { xaxis: { from: number; to: number } }) => {
        const from = Math.round(ranges.xaxis.from / 1000);
        const to = Math.round(ranges.xaxis.to / 1000);

        if (from > 0 && to > 0) {
          onTimeRangeChanged(from, to);
        }
      }
    );

    return () => {
      $(idSelector).unbind('plotselected');
    };
  });

  return (
    <ReactFlot
      height="120px"
      id={id}
      options={{
        selection: {
          mode: 'x',
        },
        grid: {
          clickable: true,
          autoHighlight: false,
        },
        yaxis: {
          min: 0,
          show: false,
        },
        xaxis: {
          mode: 'time',
          reserveSpace: false,
          // according to https://github.com/flot/flot/blob/master/API.md#customizing-the-axes
          minTickSize: [3, 'second'],
        },
      }}
      data={[
        {
          data: timeline,
          lines: { show: false },
          points: { show: true },
        },
      ]}
    />
  );
}
function ciEventToTimeline(event: Event) {
  return [new Date(event.date), event.value, event];
}

function findClickedItem(events: Event[], timestamp: number, value: number) {
  // We are assuming only a single item per value/timestamp exists
  // Which may not be true
  const item = events.find((a) => {
    const dateMatch =
      new Date(a.date).getTime() === new Date(timestamp).getTime();
    return dateMatch && value === a.value;
  });

  return Maybe.of(item);
}
