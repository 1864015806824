import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import {
  MenuButton,
  MenuDivider,
  MenuProps,
  MenuHeader,
} from '@szhsin/react-menu';
import Dropdown, { MenuItem as DropdownMenuItem } from '@webapp/ui/Dropdown';
import flattenChildren from 'react-flatten-children';
import Icon from '@webapp/ui/Icon';
import { MenuItem } from '@webapp/ui/Sidebar';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { selectCurrentUser } from '@webapp/redux/reducers/user';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { Organization } from '../models/organizations';
import { selectCurrentOrganization } from '../redux/reducers/organization';
import {
  loadOrganizations,
  selectOrganizations,
} from '../redux/reducers/organizations';
import { redirectToLogout } from '../services/login';
import { switchOrganization } from '../services/organizations';
import styles from './AccountButton.module.css';

import NewOrganizationModal from './NewOrganizationModal';
import EntityIcon from './EntityIcon';
import InviteMembersModal from './InviteMembersModal';
import MenuItemWithButton from './MenuItemWithButton';

export interface DropdownProps {
  children: JSX.Element[] | JSX.Element;
  offsetX: MenuProps['offsetX'];
  offsetY: MenuProps['offsetY'];
  direction: MenuProps['direction'];
  label: string;
  className: string;
  menuButton: JSX.Element;
}

function FlatDropdown({
  children,
  offsetX,
  offsetY,
  direction,
  label,
  className,
  menuButton,
}: DropdownProps) {
  return (
    <Dropdown
      offsetX={offsetX}
      offsetY={offsetY}
      direction={direction}
      label={label}
      className={className}
      menuButton={menuButton}
    >
      {flattenChildren(children) as unknown as JSX.Element}
    </Dropdown>
  );
}

function Org({ name }: { name: string }) {
  return (
    <>
      <EntityIcon type="organization" id={name} />
      &nbsp;{name}
    </>
  );
}

function Person({ name }: { name: string }) {
  return (
    <>
      <EntityIcon type="person" id={name} /> {name}&nbsp;&nbsp;
    </>
  );
}

export default function AccountButton() {
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [inviteMemberModal, setInviteMemberModal] = useState(false);
  const history = useHistory();

  const newOrgModal = () => {
    setShowOrgModal(true);
  };

  const inviteMembersModal = () => {
    setInviteMemberModal(true);
  };

  const switchOrg = async (o: Organization) => {
    const res = await switchOrganization(o);
    if (res.isOk) {
      // document.location.reload();
      document.location.href = '/';
    } else {
      // eslint-disable-next-line no-console
      console.error(res.error.message);
    }
  };
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(selectCurrentUser);
  const currentOrg = useAppSelector(selectCurrentOrganization);
  const organizations = useAppSelector(selectOrganizations) as Organization[];

  useEffect(() => {
    async function loadOrganization() {
      await dispatch(loadOrganizations()).unwrap();
    }

    if (!organizations) {
      void loadOrganization();
    }
  }, [organizations, dispatch]);

  return (
    <>
      <FlatDropdown
        offsetX={10}
        offsetY={5}
        direction="top"
        label="Signed In As"
        className={styles.dropdown}
        menuButton={
          <MenuButton className={styles.accountDropdown}>
            <MenuItem icon={<Icon icon={faUser} />}>Account</MenuItem>
          </MenuButton>
        }
      >
        <DropdownMenuItem className={styles.menuItemDisabled}>
          <MenuItemWithButton
            title={<Person name={currentUser?.name || '_'} />}
            icon={faCog}
            route="/settings"
          />
        </DropdownMenuItem>
        <MenuDivider className={styles.menuDivider} />
        <MenuHeader>Current Organization</MenuHeader>
        <DropdownMenuItem className={styles.menuItemDisabled}>
          <MenuItemWithButton
            title={<Org name={currentOrg?.displayName || '_'} />}
            icon={faCog}
            route="/settings"
          />
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => history.push('/settings/billing')}>
          <Icon icon={faReceipt} />
          &nbsp;&nbsp;Billing
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => inviteMembersModal()}>
          <Icon icon={faPlus} />
          &nbsp;&nbsp;Invite Members
        </DropdownMenuItem>
        <MenuDivider className={styles.menuDivider} />
        <MenuHeader>Other Organizations</MenuHeader>
        <>
          {(organizations || []).map((o) => {
            if (o.id === currentOrg?.id) {
              return null;
            }
            return (
              <DropdownMenuItem onClick={() => void switchOrg(o)} key={o.id}>
                <Org name={o.displayName} />
              </DropdownMenuItem>
            );
          })}
        </>
        <DropdownMenuItem onClick={() => newOrgModal()}>
          <Icon icon={faPlus} />
          &nbsp;&nbsp;New Organization
        </DropdownMenuItem>
        <MenuDivider className={styles.menuDivider} />
        <DropdownMenuItem onClick={() => redirectToLogout()}>
          <Icon icon={faSignOutAlt} />
          &nbsp;&nbsp;Sign Out
        </DropdownMenuItem>
      </FlatDropdown>
      {showOrgModal ? (
        <NewOrganizationModal closable onClose={() => setShowOrgModal(false)} />
      ) : null}
      {inviteMemberModal ? (
        <InviteMembersModal onClose={() => setInviteMemberModal(false)} />
      ) : null}
    </>
  );
}
