import { Switch, Route, useRouteMatch, NavLink } from 'react-router-dom';
import Box from '@webapp/ui/Box';
import Icon from '@webapp/ui/Icon';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
// import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons/faNetworkWired';
import cx from 'classnames';
import { selectCurrentUser } from '@webapp/redux/reducers/user';
import { User } from '@webapp/models/users';
import PageTitle from '@webapp/components/PageTitle';
import styles from '@webapp/components/Settings/Settings.module.css';
import Apps from '@webapp/components/Settings/Apps';
import { useAppSelector } from '../../redux/hooks';
import ApiKeys from './APIKeys';
import Users from './Users';
import Invites from './Invites';
import APIKeyAddForm from './APIKeys/APIKeyAddForm';
import Usage from '../../pages/Usage';
import Billing from '../../pages/Billing';

function Settings() {
  const { path, url } = useRouteMatch();
  const currentUser = useAppSelector(selectCurrentUser);

  const isAdmin = (user: User) => user && user.role === 'Admin';
  const isExternal = (user: User) => user && user.isExternal;
  return (
    <div className="pyroscope-app">
      <h1>Settings</h1>
      <nav>
        <ul className={styles.settingsNav}>
          <li>
            <NavLink
              to={`${url}`}
              exact
              className={(isActive) =>
                cx({
                  [styles.navLink]: true,
                  [styles.navLinkActive]: isActive,
                })
              }
              data-testid="userstab"
            >
              <Icon icon={faUser} /> Members
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to={`${url}`}
              exact
              className={(isActive) =>
                cx({ [styles.navLink]: true, [styles.navLinkActive]: isActive })
              }
              data-testid="settings-profiletab"
            >
              <Icon icon={faSlidersH} /> Profile
            </NavLink>
          </li> */}
          {currentUser && !isExternal(currentUser) && (
            <>
              <li>
                <NavLink
                  to={`${url}/security`}
                  className={(isActive) =>
                    cx({
                      [styles.navLink]: true,
                      [styles.navLinkActive]: isActive,
                    })
                  }
                  data-testid="settings-changepasswordtab"
                >
                  <Icon icon={faLock} /> Change Password
                </NavLink>
              </li>
            </>
          )}
          {currentUser && isAdmin(currentUser) ? (
            <>
              <li>
                <NavLink
                  to={`${url}/api-keys`}
                  className={(isActive) =>
                    cx({
                      [styles.navLink]: true,
                      [styles.navLinkActive]: isActive,
                    })
                  }
                  data-testid="settings-apikeystab"
                >
                  <Icon icon={faKey} /> API keys
                </NavLink>
              </li>
            </>
          ) : null}
          <li>
            <NavLink
              to={`${url}/usage`}
              className={(isActive) =>
                cx({
                  [styles.navLink]: true,
                  [styles.navLinkActive]: isActive,
                })
              }
              data-testid="usagestab"
            >
              <Icon icon={faChartLine} /> Usage
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${url}/billing`}
              className={(isActive) =>
                cx({
                  [styles.navLink]: true,
                  [styles.navLinkActive]: isActive,
                })
              }
              data-testid="billingstab"
            >
              <Icon icon={faReceipt} /> Billing
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${url}/apps`}
              className={(isActive) =>
                cx({
                  [styles.navLink]: true,
                  [styles.navLinkActive]: isActive,
                })
              }
              data-testid="settings-appstab"
            >
              <Icon icon={faNetworkWired} /> Apps
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="main-wrapper">
        <Box className={styles.settingsWrapper}>
          <Switch>
            <Route exact path={path}>
              <PageTitle title="Settings / Members" />
              <Users />
              <Invites />
            </Route>
            <Route exact path={`${path}/usage`}>
              <PageTitle title="Settings / Usage" />
              <Usage />
            </Route>
            <Route exact path={`${path}/billing`}>
              <PageTitle title="Settings / Billing" />
              <Billing />
            </Route>
            <Route exact path={`${path}/api-keys`}>
              <PageTitle title="Settings / API Keys" />
              <ApiKeys />
            </Route>
            <Route exact path={`${path}/api-keys/add`}>
              <PageTitle title="Settings / Add API Key" />
              <APIKeyAddForm />
            </Route>
            <Route exact path={`${path}/apps`}>
              <PageTitle title="Settings / Apps" />
              <Apps />
            </Route>
          </Switch>
        </Box>
      </div>
    </div>
  );
}

export default Settings;
