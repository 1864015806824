import { useEffect, useState } from 'react';
import {
  loadCurrentOrganization,
  selectCurrentOrganization,
} from '../redux/reducers/organization';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import FullPageSpinner from './FullPageSpinner';
import NewOrganizationModal from './NewOrganizationModal';

export default function OrganizationWall({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectCurrentOrganization);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadOrganization() {
      try {
        await dispatch(loadCurrentOrganization()).unwrap();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('e', e);
        // TODO
      } finally {
        setIsLoading(false);
      }
    }

    if (!organization) {
      void loadOrganization();
    }
  }, [organization, dispatch]);

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      {children}
      {organization ? null : <NewOrganizationModal />}
    </>
  );
}
