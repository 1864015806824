import { useEffect, useState } from 'react';
import cl from 'classnames';

import TableUI from '@webapp/ui/Table';
import { useAppDispatch } from '@webapp/redux/hooks';
import { reloadUsers, selectUsers } from '@webapp/redux/reducers/settings';
import { selectCurrentUser } from '@webapp/redux/reducers/user';
import Input from '@webapp/ui/Input';
import tableStyles from '@webapp/components/Settings/SettingsTable.module.scss';
import { getUserTableRows } from './getUserTableRows';

import userStyles from './Users.module.css';
import { useAppSelector } from '../../../redux/hooks';

const headRow = [
  // { name: '', label: '', sortable: 0 },
  // { name: '', label: 'Username', sortable: 0 },
  { name: '', label: 'Name', sortable: 0 },
  { name: '', label: 'Email', sortable: 0 },
  // { name: '', label: 'Role', sortable: 0 },
  // { name: '', label: 'Updated', sortable: 0 },
  // { name: '', label: '', sortable: 0 },
];

function Users() {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const currentUser = useAppSelector(selectCurrentUser);
  const [search, setSearchField] = useState('');

  useEffect(() => {
    async function loadInvites() {
      await dispatch(reloadUsers()).unwrap();
    }

    if (!users) {
      void loadInvites();
    }
  }, [users, dispatch]);

  const displayUsers =
    (users &&
      users.filter(
        (x) =>
          JSON.stringify(x).toLowerCase().indexOf(search.toLowerCase()) !== -1
      )) ||
    [];

  if (!currentUser) return null;
  const tableBodyProps =
    displayUsers.length > 0
      ? {
          bodyRows: getUserTableRows(currentUser.id, displayUsers),
          type: 'filled' as const,
        }
      : {
          type: 'not-filled' as const,
          value: 'The list is empty',
          bodyClassName: userStyles.usersTableEmptyMessage,
        };

  return (
    <>
      <h2>Members</h2>
      <div className={userStyles.searchContainer}>
        <Input
          type="text"
          placeholder="Search for members"
          value={search}
          onChange={(v) => setSearchField(v.target.value)}
          name="Search user input"
        />
      </div>
      <TableUI
        className={cl(userStyles.usersTable, tableStyles.settingsTable)}
        table={{ headRow, ...tableBodyProps }}
      />
    </>
  );
}

export default Users;
