import type { Users } from '@webapp/models/users';
import type { BodyRow } from '@webapp/ui/Table';

export function getUserTableRows(
  currentUserId: number,
  displayUsers: Users
): BodyRow[] {
  const bodyRows = displayUsers.reduce((acc, user) => {
    const { isDisabled, fullName, email } = user;

    const row = {
      isRowDisabled: isDisabled,
      cells: [
        // { value: id },
        // { value: name },
        { value: fullName },
        { value: email },
        // { value: isCurrent ? role : <EditRoleDropdown user={user} /> },
        // { value: formatRelative(new Date(updatedAt), new Date()) },
        // {
        //   value: !isCurrent ? (
        //     <div className={styles.actions}>
        //       {/* <DisableButton user={user} onDisable={handleDisableUser} /> */}
        //       <DeleteButton user={user} onDelete={handleDeleteUser} />
        //     </div>
        //   ) : null,
        //   align: 'center',
        // },
      ],
    };

    acc.push(row);
    return acc;
  }, [] as BodyRow[]);

  return bodyRows;
}
