import { Result } from '@webapp/util/fp';
import type { ZodError } from 'zod';
import { request, parseResponse } from '@webapp/services/base';
import type { RequestError } from '@webapp/services/base';
import {
  type Organization,
  Organizations,
  fullOrganizationModel,
  organizationsModel,
} from '../models/organizations';

export async function createOrganization(
  organization: Organization
): Promise<Result<boolean, RequestError | ZodError>> {
  const response = await request(`/api/organizations`, {
    method: 'POST',
    body: JSON.stringify(organization),
  });

  if (response.isOk) {
    return Result.ok(true);
  }

  return Result.err<false, RequestError>(response.error);
}

export async function loadCurrentOrganization(): Promise<
  Result<Organization, RequestError | ZodError>
> {
  const response = await request(`/api/organization`);
  if (response.isOk) {
    return parseResponse(response, fullOrganizationModel);
  }

  return Result.err<Organization, RequestError>(response.error);
}

export async function loadOrganizations(): Promise<
  Result<Organizations, RequestError | ZodError>
> {
  const response = await request(`/api/organizations`);
  if (response.isOk) {
    return parseResponse(response, organizationsModel);
  }

  return Result.err<Organizations, RequestError>(response.error);
}

export async function switchOrganization(
  organization: Organization
): Promise<Result<boolean, RequestError | ZodError>> {
  const response = await request(`/api/organizations/switch`, {
    method: 'POST',
    body: JSON.stringify(organization),
  });

  if (response.isOk) {
    return Result.ok(true);
  }

  return Result.err<false, RequestError>(response.error);
}

export async function inviteUser(
  email: string
): Promise<Result<boolean, RequestError | ZodError>> {
  const response = await request(`/api/organization/invitations`, {
    method: 'POST',
    body: JSON.stringify({ email }),
  });

  if (response.isOk) {
    return Result.ok(true);
  }

  return Result.err<false, RequestError>(response.error);
}
