import React, { useState } from 'react';
import Button from '@webapp/ui/Button';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { createAPIKey } from '@webapp/redux/reducers/settings';
import { type APIKey } from '@webapp/models/apikeys';
import StatusMessage from '@webapp/ui/StatusMessage';
import { addNotification } from '@webapp/redux/reducers/notifications';
import styles from '@webapp/components/Settings/APIKeys/APIKeyForm.module.css';
import { useAppDispatch } from '../../../redux/hooks';

// Extend the API key, but add form validation errors and ttlSeconds
export interface APIKeyAddProps extends APIKey {
  errors?: string[];
  ttlSeconds?: number;
}

function APIKeyAddForm() {
  const [form, setForm] = useState({
    errors: [],
    name: '',
    role: 'ReadOnly',
    ttlSeconds: 360000,
  });
  const [key, setKey] = useState<string>();
  const dispatch = useAppDispatch();

  const handleFormSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      name: form.name,
      role: form.role,
      ttlSeconds: Number(form.ttlSeconds),
    };
    dispatch(createAPIKey(data))
      .unwrap()
      .then(
        (k) => {
          // TODO?
          if (k.key) {
            setKey(k.key);
          }
        },
        (e: ShamefulAny) => {
          function hasErrors(err: unknown): err is { errors: ShamefulAny } {
            const e = err as { errors: ShamefulAny };

            return 'errors' in e;
          }

          if (hasErrors(e)) {
            setForm({ ...form, errors: e.errors });
          }
        }
      );
  };

  const handleKeyCopy = () => {
    void dispatch(
      addNotification({
        type: 'success',
        title: 'Success',
        message: 'Key has been copied',
      })
    );
  };

  return (
    <>
      <h2>Create API Key</h2>

      <form onSubmit={handleFormSubmit}>
        {key ? (
          <div>
            <StatusMessage
              type="success"
              message="Key has been successfully created. Click the button below to copy
              it."
            />
            <div>
              <CopyToClipboard text={key} onCopy={handleKeyCopy}>
                <Button icon={faCopy} className={styles.keyOutput}>
                  {key}
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        ) : (
          <>
            {/* <InputField
              label="Name"
              placeholder="Name"
              id="keyName"
              type="text"
              name="name"
              value={form.name}
              onChange={handleFormChange}
              required
            />
            <div>
              <h4>Role</h4>
              <Dropdown
                onItemClick={(i) => handleRoleChange(i.value)}
                value={form.role}
                label="Role"
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="ReadOnly">ReadOnly</MenuItem>
                <MenuItem value="Agent">Agent</MenuItem>
              </Dropdown>
            </div>
            <InputField
              label="Valid for (seconds):"
              id="keyTTL"
              name="ttlSeconds"
              value={form.ttlSeconds}
              onChange={handleFormChange}
            /> */}
            <p>
              API keys allow you to upload data to Pyroscope Cloud. You can use
              API keys as authentication tokens in pyroscope integrations.
            </p>
            <div>
              <Button icon={faPlus} type="submit" kind="secondary">
                Create API Key
              </Button>
            </div>
          </>
        )}
      </form>
    </>
  );
}

export default APIKeyAddForm;
