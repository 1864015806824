import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import {
  reloadAppNames,
  selectAppNames,
  selectAppNamesState,
  setQuery,
  selectApplicationName,
} from '@webapp/redux/reducers/continuous';
import { queryFromAppName } from '@webapp/models/query';
import { reloadApiKeys, selectAPIKeys } from '@webapp/redux/reducers/settings';
import { addNotification } from '@webapp/redux/reducers/notifications';
import { fetchApps } from '@webapp/services/apps';
import Button from '@webapp/ui/Button';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@webapp/ui/Dialog';
import LoadingSpinner from '@webapp/ui/LoadingSpinner';
import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Bugsnag from '@bugsnag/browser';
import FullPageSpinner from './FullPageSpinner';
import styles from './NoDataWall.module.css';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

type Integration = {
  title: string;
  url: string;
};

const integrations: Integration[] = [
  { title: 'Go', url: 'https://pyroscope.io/docs/golang/' },
  { title: 'Java', url: 'https://pyroscope.io/docs/java/' },
  { title: 'Python', url: 'https://pyroscope.io/docs/python/' },
  { title: 'Ruby', url: 'https://pyroscope.io/docs/ruby/' },
  // TODO(petethepig): this is not the best page, it's outdated IMO:
  { title: 'Other', url: 'https://pyroscope.io/docs/agent-overview/' },
];

const openURL = (url: string) => {
  window.open(url, '_blank');
};

export default function NoDataWall({
  children,
}: {
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  const appNamesState = useAppSelector(selectAppNamesState);
  const appNames = useAppSelector(selectAppNames);
  const [activeIntegration, setActiveIntegration] = useState(
    null as Integration | null
  );
  // TODO: false as default
  const [isDialogOpen, setDialogOpen] = useState(true);

  const apiKeys = useAppSelector(selectAPIKeys);

  useEffect(() => {
    async function loadAPIKeys() {
      try {
        await dispatch(reloadApiKeys()).unwrap();
      } catch (e: unknown) {
        Bugsnag.notify(e as Error);
      } finally {
        setIsLoading(false);
      }
    }

    if (!apiKeys) {
      void loadAPIKeys();
    }
  }, [apiKeys, dispatch]);

  // TODO(petethepig): this seems more complicated than it needs to be:
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  useEffect(() => {
    void dispatch(reloadAppNames());
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && appNames.length > 0) {
      setHasLoadedOnce(true);
    }
  }, [isLoading, appNames]);

  useEffect(() => {
    async function run() {
      const res = await fetchApps();

      if (res.isOk && res.value.length > 0) {
        void dispatch(reloadAppNames());
      }
    }

    const interval = setInterval(function () {
      if (appNames.length === 0) {
        void run();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [appNames, dispatch]);

  // This is copied from Continuous.tsx:
  // Pick the first one if there's nothing selected
  const selectedAppName = useAppSelector(selectApplicationName);
  useEffect(() => {
    if (!selectedAppName && appNames.length > 0) {
      dispatch(setQuery(queryFromAppName(appNames[0])));
    }
  }, [appNames, selectedAppName, dispatch]);

  const handleKeyCopy = () => {
    void dispatch(
      addNotification({
        type: 'success',
        title: 'Success',
        message: 'Key has been copied',
      })
    );
  };

  const handleAddressCopy = () => {
    void dispatch(
      addNotification({
        type: 'success',
        title: 'Success',
        message: 'Server Address has been copied',
      })
    );
  };

  if (!hasLoadedOnce && (isLoading || appNamesState.type !== 'loaded')) {
    return <FullPageSpinner />;
  }

  const key = apiKeys && apiKeys[0] ? apiKeys[0].name : null;

  const ingestURL = 'https://ingest.pyroscope.cloud';

  const Integration = () => {
    if (!activeIntegration) {
      return null;
    }
    return (
      <>
        <h3>{activeIntegration.title} Instructions</h3>
        <h4>Step 1: Click below to copy the API key</h4>
        <p>Use the following API key for Auth Token:</p>
        <p>
          <CopyToClipboard text={key} onCopy={handleKeyCopy}>
            <Button icon={faCopy} className={styles.keyButton}>
              {key}
            </Button>
          </CopyToClipboard>
        </p>
        <h4>Step 2: Integrate with Pyroscope Cloud</h4>
        <p>Use the following URL for Server Address:</p>
        <CopyToClipboard text={ingestURL} onCopy={handleAddressCopy}>
          <Button icon={faCopy} className={styles.addressButton}>
            {ingestURL}
          </Button>
        </CopyToClipboard>
        <p>
          <Button
            kind="secondary"
            onClick={() => openURL(activeIntegration.url)}
          >
            Open {activeIntegration.title} Documentation
          </Button>
        </p>
        <h4>Step 3: Start using Pyroscope Cloud</h4>
        <p>
          Once you have integrated and sent an event, we will verify it was
          properly received and continue.
        </p>
      </>
    );
  };

  const dialog = (
    <>
      <Dialog open={isDialogOpen} aria-labelledby="dialog-header">
        <>
          <DialogHeader closeable onClose={() => setDialogOpen(false)}>
            <h3 id="dialog-header">Welcome to Pyroscope Cloud!</h3>
          </DialogHeader>
          <DialogBody>
            <>
              <p>
                Let&rsquo;s get you up and running with Pyroscope! What kind of
                platform is your app? (you can set up other apps later)
              </p>
              <ul className={styles.integrations}>
                {integrations.map((integration) => {
                  return (
                    <li key={integration.title} className={styles.integration}>
                      <Button
                        className={styles.integrationButton}
                        kind={
                          activeIntegration === integration
                            ? 'secondary'
                            : 'default'
                        }
                        onClick={() => setActiveIntegration(integration)}
                      >
                        {integration.title}
                      </Button>
                    </li>
                  );
                })}
              </ul>
              <Integration />
            </>
          </DialogBody>
          <DialogFooter>
            <div className={styles.dialogFooter}>
              <div className={styles.listeningWrapper}>
                Listening for new events...&nbsp;
                <LoadingSpinner /> &nbsp; &nbsp;
              </div>
              <Button kind="secondary" onClick={() => setDialogOpen(false)}>
                Skip onboarding for now
              </Button>
            </div>
          </DialogFooter>
        </>
      </Dialog>
    </>
  );

  return (
    <>
      {children}
      {appNames.length <= 0 && dialog}
    </>
  );
}
