import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@cloudstorage/redux/store';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistedState } from 'redux-persist/lib/types';
import * as thunks from './ci/thunks';
import { initialState } from './ci/state';
import * as selectors from './ci/selectors';

// Persistence Migrations
// See examples on https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
export const migrations = {
  0: (state: PersistedState) => {
    if (!state) {
      return {} as PersistedState;
    }

    return { ...state };
  },
};

export const ciPersistConfig = {
  key: 'pyroscope:ci',
  version: 0,
  storage,
  migrate: createMigrate(migrations, {
    debug: process.env.NODE_ENV === 'development',
  }),
  whitelist: ['disableOnboarding'],
};

export const ciState = createSlice({
  name: 'ci',
  initialState,
  reducers: {
    setSelectedBranch(state, action: PayloadAction<string | undefined>) {
      state.selectedBranchName = action.payload;
    },
    setSelectedEventID(state, action: PayloadAction<string>) {
      state.selectedEventID = action.payload;
    },
    toggleSelectedEvent(state, action: PayloadAction<string>) {
      if (state.selectedEventID === action.payload) {
        state.selectedEventID = initialState.selectedEventID;
        state.profile = initialState.profile;
      } else {
        state.selectedEventID = action.payload;
      }
    },

    clearEvents(state) {
      state.events = initialState.events;
    },

    disableOnboarding(state) {
      state.disableOnboarding = true;
    },
  },
  extraReducers: (builder) => {
    // Applications
    builder.addCase(thunks.loadApplicationsThunk.fulfilled, (state, action) => {
      // Convert an array of applications into a record
      // So that we can access each application (and its branches) with app[name]
      const data = action.payload.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.name]: {
            data: curr,
            branches: { type: 'pristine' },
          },
        };
      }, {});

      state.applications = {
        type: 'loaded',
        data,
      };
    });

    builder.addCase(thunks.loadApplicationsThunk.pending, (state) => {
      state.applications = { type: 'loading' };
    });

    builder.addCase(thunks.loadApplicationsThunk.rejected, (state) => {
      state.applications = { type: 'pristine' };
    });

    // Branches
    builder.addCase(thunks.loadBranchesThunk.pending, (state, action) => {
      // Ignore the other states, since it means we are loading a branch
      // When applications have not been loaded!
      if (state.applications.type === 'loaded') {
        state.applications.data[action.meta.arg.name] = {
          data: action.meta.arg,
          branches: {
            type: 'loading',
          },
        };
      }
    });

    builder.addCase(thunks.loadBranchesThunk.fulfilled, (state, action) => {
      // Ignore the other states, since it means we are loading a branch
      // When applications have not been loaded!
      if (state.applications.type === 'loaded') {
        state.applications.data[action.meta.arg.name] = {
          data: action.meta.arg,
          branches: {
            type: 'loaded',
            data: action.payload,
          },
        };
      }
    });

    // Events
    builder.addCase(thunks.loadEventsThunk.pending, (state) => {
      state.events = { type: 'loading' };
    });

    builder.addCase(thunks.loadEventsThunk.fulfilled, (state, action) => {
      state.events = { type: 'loaded', data: action.payload };

      // The selectors expect the entire root state, let's fake it just to not complicate things
      if (
        selectors.selectSelectedEvent({
          ci: { ...state, events: { type: 'loaded', data: action.payload } },
        } as RootState).isNothing
      ) {
        // When loading more events, let's remove the existing selected item
        // Unless the selected event is still valid (eg when going from 5m to 10m)
        state.profile = { type: 'pristine' };
        state.selectedEventID = undefined;
      }
    });

    // Event Profile
    builder.addCase(thunks.loadEventProfileThunk.pending, (state) => {
      state.profile = { ...state.profile, type: 'loading' };
    });

    builder.addCase(thunks.loadEventProfileThunk.fulfilled, (state, action) => {
      state.profile = {
        ...state.profile,
        type: 'loaded',
        data: action.payload,
      };
    });
  },
});

export const { actions } = ciState;
export default ciState.reducer;
