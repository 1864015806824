import { Application, Branch, Event } from '@cloudstorage/models/ci';
import { createAsyncThunk } from '@webapp/redux/async-thunk';
import {
  loadApplications,
  loadBranches,
  loadEventProfile,
  loadEvents,
} from '@cloudstorage/services/ci';
import { formatAsOBject } from '@webapp/util/formatDate';
import { addNotification } from '@webapp/redux/reducers/notifications';

export const loadEventsThunk = createAsyncThunk(
  'ci/loadEvents',
  async (
    payload: {
      app: Application;
      branch: Branch;
      from: number | string;
      until: number | string;
    },
    thunkAPI
  ) => {
    const res = await loadEvents(
      payload.app.name,
      payload.branch.name,
      formatAsOBject(payload.from.toString()),
      formatAsOBject(payload.until.toString())
    );

    if (res.isOk) {
      return Promise.resolve(res.value);
    }

    void thunkAPI.dispatch(
      addNotification({
        type: 'danger',
        title: 'Failed to load events',
        message: res.error.message,
      })
    );

    return Promise.reject(res.error);
  }
);

export const loadApplicationsThunk = createAsyncThunk(
  'ci/loadApplications',
  async (_, thunkAPI) => {
    const res = await loadApplications();

    if (res.isOk) {
      return Promise.resolve(res.value);
    }

    void thunkAPI.dispatch(
      addNotification({
        type: 'danger',
        title: 'Failed to load apps',
        message: res.error.message,
      })
    );

    return Promise.reject(res.error);
  }
);

export const loadBranchesThunk = createAsyncThunk(
  'ci/loadBranches',
  async (app: Application, thunkAPI) => {
    const res = await loadBranches(app.name);

    if (res.isOk) {
      return Promise.resolve(res.value);
    }

    void thunkAPI.dispatch(
      addNotification({
        type: 'danger',
        title: 'Failed to load branches',
        message: res.error.message,
      })
    );

    return Promise.reject(res.error);
  }
);

export const loadEventProfileThunk = createAsyncThunk(
  'ci/loadEventProfile',
  async (event: Event, thunkAPI) => {
    const res = await loadEventProfile(event.id, event.name);

    if (res.isOk) {
      return Promise.resolve(res.value.profile);
    }

    void thunkAPI.dispatch(
      addNotification({
        type: 'danger',
        title: 'Failed to load profile',
        message: res.error.message,
      })
    );

    return Promise.reject(res.error);
  }
);
