import React, { useEffect } from 'react';
import Button from '@webapp/ui/Button';
import Icon from '@webapp/ui/Icon';

import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { useHistory } from 'react-router-dom';

import {
  reloadApiKeys,
  selectAPIKeys,
  deleteAPIKey,
} from '@webapp/redux/reducers/settings';
import confirmDelete from '@webapp/components/Modals/ConfirmDelete';
import styles from '@webapp/components/Settings/SettingsTable.module.scss';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import styles2 from './index.module.css';

const ApiKeys = () => {
  const dispatch = useAppDispatch();
  const apiKeys = useAppSelector(selectAPIKeys);
  const history = useHistory();

  useEffect(() => {
    const reload = dispatch(reloadApiKeys());
    return reload.abort;
  }, [dispatch]);

  const onDelete = (key: ShamefulAny) => {
    void dispatch(deleteAPIKey(key))
      .unwrap()
      .then(() => {
        void dispatch(reloadApiKeys());
      });
  };

  const handleDeleteClick = (key: { name: string }) => {
    confirmDelete({
      objectType: 'key',
      objectName: key.name,
      onConfirm: () => onDelete(key),
    });
  };

  return (
    <>
      <h2>API Keys</h2>
      <p>
        API keys allow you to send events to Pyroscope via /ingest endpoint.
        Refer to{' '}
        <a
          href="https://pyroscope.io/docs/agent-overview/"
          target="_blank"
          rel="noreferrer"
          className={styles2.link}
        >
          our documentation
        </a>{' '}
        for more information on how to start sending data into pyroscope.
      </p>
      <div>
        <Button
          type="submit"
          kind="secondary"
          icon={faPlus}
          onClick={() => history.push('/settings/api-keys/add')}
        >
          Add Key
        </Button>
      </div>
      <table className={styles.settingsTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th aria-label="Actions" />
          </tr>
        </thead>
        <tbody>
          {apiKeys &&
            apiKeys.map((key) => (
              <tr key={key.id}>
                <td>{key.name}</td>
                <td align="center">
                  <Button
                    type="submit"
                    kind="danger"
                    aria-label="Delete key"
                    onClick={() => handleDeleteClick(key)}
                  >
                    <Icon icon={faTimes} />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default ApiKeys;
