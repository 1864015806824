import { createRoot } from 'react-dom/client';
import './index.css';
// This needs to be before the application, since it's assume to be bundled to the global scope
import './jquery-import';
import App from './App';

// async function bootstrap() {
//   // Just to serve as an example on how the initial delay can be async
//   // So that we could for eg load initial data from a server?
//   const initialDelay = 0;
//   await delay(initialDelay);
//
//   const container = document.getElementById('root');
//   const root = createRoot(container as Element);
//   root.render(<App />);
// }
//
// function delay(d: number) {
//   return new Promise(function (resolve) {
//     setTimeout(resolve, d);
//   });
// }

// void bootstrap();
const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
