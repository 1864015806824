import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@webapp/redux/async-thunk';
import type { Plans } from '../../models/plans';

import { loadPlans as loadPlansAPI } from '../../services/plans';

import type { RootState } from '../store';

// This was copied from user.ts in pyroscope-oss

interface PlansRootState {
  type: 'loading' | 'loaded' | 'failed';
  data?: Plans;
}

// Define the initial state using that type
const initialState: PlansRootState = {
  type: 'loading',
  data: undefined,
};

export const loadPlans = createAsyncThunk('newRoot/loadPlans', async () => {
  const res = await loadPlansAPI();
  if (res.isOk) {
    return Promise.resolve(res.value);
  }

  return Promise.reject(res.error);
});

const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadPlans.fulfilled, (state, action) => {
      return { type: 'loaded', data: action.payload };
    });
    builder.addCase(loadPlans.pending, (state) => {
      return { type: 'loading', data: state.data };
    });
    builder.addCase(loadPlans.rejected, (state) => {
      return { type: 'failed', data: state.data };
    });
  },
});

export const PlansState = (state: RootState) => state.plans;
export const selectPlans = (state: RootState) => state.plans?.data;

export default planSlice.reducer;
