import clsx from 'clsx';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import murmurhash3 from '@pyroscope/flamegraph/src/FlameGraph/FlameGraphComponent/murmur3';
import styles from './EntityIcon.module.css';

const seed = 6231912;

type EntityIconProps = {
  type: 'person' | 'organization';
  id: string;
};

function pastelColor(v1: number, v2: number, v3: number) {
  return `hsl(${360 * v1},${50 + (100 - 50) * v2}%,${55 + (90 - 55) * v3}%)`;
}

const uint32max = 2 ** 32;

function colorForId(id: string) {
  const v1 = murmurhash3(id, seed) / uint32max;
  const v2 = murmurhash3(id, seed / 3) / uint32max;
  const v3 = murmurhash3(id, seed / 2) / uint32max;
  return pastelColor(v1, v2, v3);
}

export default function EntityIcon({ type, id }: EntityIconProps) {
  const style = {
    backgroundColor: colorForId(id),
  };

  const class2 = {
    organization: styles.organization,
    person: styles.person,
  }[type];

  const firstLetter = (id.charAt(0) || '_').toUpperCase();
  return (
    <span className={clsx(styles.entityIcon, class2)} style={style}>
      {firstLetter}
    </span>
  );
}
