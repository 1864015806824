import { z, ZodError } from 'zod';
import { Result } from '@webapp/util/fp';
import { modelToResult } from '@webapp/models/utils';

const zDateTime = z.string().transform((value: string | number | Date) => {
  if (typeof value === 'string') {
    const date = Date.parse(value);
    if (Number.isInteger(date)) {
      return date;
    }
    return value;
  }
  if (typeof value === 'number') {
    return new Date(value);
  }
  return value;
});

export const inviteModel = z.object({
  inviter: z.string(),
  invitee: z.string(),
  createdAt: zDateTime,
  expiresAt: zDateTime,
});

export const invitesModel = z.array(inviteModel);

export type Invites = z.infer<typeof invitesModel>;
export type Invite = z.infer<typeof inviteModel>;

export function parse(a: unknown): Result<Invites, ZodError> {
  return modelToResult(invitesModel, a);
}
