import posthog from 'posthog-js';
import { useEffect } from 'react';

// TODO: get from env vars
const POSTHOG_APP_ID = 'phc_9qwPHOP9yv0PnyXgwRbQRiXQT4MNI8Eih1zyY6JrKJ';

export function usePosthog() {
  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_POSTHOG) {
      posthog.init(POSTHOG_APP_ID, { api_host: 'https://app.posthog.com' });
    }
  }, []);
}
