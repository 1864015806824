import { selectCurrentUser } from '@webapp/redux/reducers/user';

import { useEffect, useState } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { selectCurrentOrganization } from '../redux/reducers/organization';
import { useAppSelector } from '../redux/hooks';
import FullPageSpinner from './FullPageSpinner';

const ldDevKey = '62800209c493b014f6b7e999';
const ldProdKey = '62800209c493b014f6b7e99a';

export default function FeatureFlags({
  children,
}: {
  children: React.ReactNode;
}) {
  const organization = useAppSelector(selectCurrentOrganization);
  const user = useAppSelector(selectCurrentUser);

  const [LDProvider, setLDProvider] = useState<{ f: ShamefulAny }>();

  const prodMode = location.hostname.match(/pyroscope\.cloud/);
  const ldKey = prodMode ? ldProdKey : ldDevKey;

  useEffect(() => {
    async function loadFlags(tenantId: string) {
      const config = {
        clientSideID: ldKey,
        user: {
          key: user?.email || 'n/a',
          custom: {
            'tenant-id': tenantId,
          },
        },
      };
      const ld = await asyncWithLDProvider(config);
      setLDProvider({ f: ld });
    }

    if (user?.email && organization?.name) {
      void loadFlags(organization.name);
    }
  }, [user?.email, organization?.name]);

  if (!LDProvider) {
    return <FullPageSpinner />;
  }

  return <LDProvider.f>{children}</LDProvider.f>;
}
