import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons/faHandPointRight';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons/faCodeBranch';
import { useWindowWidth } from '@react-hook/window-size';

import { PAGES } from '@webapp/pages/constants';
import {
  collapseSidebar,
  recalculateSidebar,
  selectSidebarCollapsed,
  uncollapseSidebar,
} from '@webapp/redux/reducers/ui';
import Icon from '@webapp/ui/Icon';
import Sidebar, {
  Menu,
  MenuItem,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from '@webapp/ui/Sidebar';
import clsx from 'clsx';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { selectCurrentOrganization } from '@cloudstorage/redux/reducers/organization';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import AccountButton from './AccountButton';
import SupportButton from './SupportButton';
import styles from './Sidebar.module.css';

export function Sidebar2() {
  const collapsed = useAppSelector(selectSidebarCollapsed);
  const currentOrg = useAppSelector(selectCurrentOrganization);
  const dispatch = useAppDispatch();

  const { search, pathname } = useLocation();
  const windowWidth = useWindowWidth();

  // the component doesn't seem to support setting up an active item
  // so we must set it up manually
  // https://github.com/azouaoui-med/react-pro-sidebar/issues/84
  const isRouteActive = (route: string) => {
    if (
      route === PAGES.CONTINOUS_SINGLE_VIEW ||
      route === PAGES.COMPARISON_VIEW ||
      route === PAGES.ADHOC_COMPARISON ||
      route === PAGES.TRACING_EXEMPLARS_SINGLE ||
      route === PAGES.TRACING_EXEMPLARS_MERGE
    ) {
      return pathname === route;
    }

    return pathname.startsWith(route);
  };

  React.useLayoutEffect(() => {
    dispatch(recalculateSidebar());
  }, [windowWidth, dispatch]);

  const isUsageActive = isRouteActive('/settings/usage');
  const isSettingsActive = isRouteActive('/settings') && !isUsageActive;

  const toggleCollapse = () => {
    const action = collapsed ? uncollapseSidebar : collapseSidebar;
    dispatch(action());
  };

  const isContinuousActive =
    isRouteActive(PAGES.CONTINOUS_SINGLE_VIEW) ||
    isRouteActive(PAGES.COMPARISON_VIEW) ||
    isRouteActive(PAGES.COMPARISON_DIFF_VIEW) ||
    isRouteActive(PAGES.TAG_EXPLORER);
  const isAdhocActive =
    isRouteActive(PAGES.ADHOC_SINGLE) ||
    isRouteActive(PAGES.ADHOC_COMPARISON) ||
    isRouteActive(PAGES.ADHOC_COMPARISON_DIFF);
  const isTracingActive =
    isRouteActive(PAGES.TRACING_EXEMPLARS_MERGE) ||
    isRouteActive(PAGES.TRACING_EXEMPLARS_SINGLE);

  let usageIconClassName: string;
  if (currentOrg?.throttlingStatus === 'throttled') {
    usageIconClassName = styles.throttlingStatusThrottled;
  } else if (currentOrg?.throttlingStatus === 'warning') {
    usageIconClassName = styles.throttlingStatusWarning;
  } else {
    usageIconClassName = styles.throttlingStatusOK;
  }
  return (
    <Sidebar collapsed={collapsed} className={styles.sidebar}>
      <SidebarHeader>
        <div className={clsx(styles.logo)}>
          <div className="logo-main" />
          <span
            className={clsx(
              styles.logoText,
              `${collapsed ? styles.logoTextCollapsed : ''}`
            )}
          >
            Pyroscope Cloud
          </span>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square" popperArrow>
          <SubMenu
            title="Continuous Profiling"
            icon={<Icon icon={faSync} />}
            active={isContinuousActive}
            defaultOpen={isContinuousActive}
            data-testid="sidebar-continuous"
          >
            <MenuItem
              data-testid="sidebar-explore-page"
              active={isRouteActive('/explore')}
              icon={<Icon icon={faSearch} />}
            >
              Tag Explorer
              <NavLink to={{ pathname: '/explore', search }} exact />
            </MenuItem>
            <MenuItem
              data-testid="sidebar-continuous-single"
              active={isRouteActive('/')}
              icon={<Icon icon={faWindowMaximize} />}
            >
              Single View
              <NavLink
                activeClassName="active-route"
                data-testid="sidebar-root"
                to={{ pathname: '/', search }}
                exact
              />
            </MenuItem>
            <MenuItem
              data-testid="sidebar-continuous-comparison"
              active={isRouteActive('/comparison')}
              icon={<Icon icon={faColumns} />}
            >
              Comparison View
              <NavLink to={{ pathname: '/comparison', search }} exact />
            </MenuItem>
            <MenuItem
              data-testid="sidebar-continuous-diff"
              active={isRouteActive('/comparison-diff')}
              icon={<Icon icon={faChartBar} />}
            >
              Diff View
              <NavLink to={{ pathname: '/comparison-diff', search }} exact />
            </MenuItem>
          </SubMenu>
          <SubMenu
            title="Adhoc Profiling"
            icon={<Icon icon={faHandPointRight} />}
            active={isAdhocActive}
            defaultOpen={isAdhocActive}
            data-testid="sidebar-adhoc"
          >
            <MenuItem
              data-testid="sidebar-adhoc-single"
              active={isRouteActive(PAGES.ADHOC_SINGLE)}
              icon={<Icon icon={faWindowMaximize} />}
            >
              Single View
              <NavLink to={{ pathname: PAGES.ADHOC_SINGLE, search }} exact />
            </MenuItem>
            <MenuItem
              data-testid="sidebar-adhoc-comparison"
              active={isRouteActive(PAGES.ADHOC_COMPARISON)}
              icon={<Icon icon={faColumns} />}
            >
              Comparison View
              <NavLink
                to={{ pathname: PAGES.ADHOC_COMPARISON, search }}
                exact
              />
            </MenuItem>
            <MenuItem
              data-testid="sidebar-adhoc-comparison-diff"
              active={isRouteActive(PAGES.ADHOC_COMPARISON_DIFF)}
              icon={<Icon icon={faChartBar} />}
            >
              Diff View
              <NavLink
                to={{ pathname: PAGES.ADHOC_COMPARISON_DIFF, search }}
                exact
              />
            </MenuItem>
          </SubMenu>
          <SubMenu
            title="Tracing Exemplars"
            icon={<Icon icon={faChartLine} />}
            active={isTracingActive}
            defaultOpen={isTracingActive}
          >
            <MenuItem
              active={isRouteActive(PAGES.TRACING_EXEMPLARS_SINGLE)}
              icon={<Icon icon={faWindowMaximize} />}
            >
              Exemplars
              <NavLink
                activeClassName="active-route"
                to={{ pathname: PAGES.TRACING_EXEMPLARS_SINGLE, search }}
                exact
              />
            </MenuItem>
            {isRouteActive('/tracing') ? (
              <MenuItem
                data-testid="sidebar-tracing"
                active={isRouteActive('/tracing')}
                icon={<Icon icon={faChartBar} />}
              >
                Merge View
                <NavLink to={{ pathname: '/tracing', search }} exact />
              </MenuItem>
            ) : null}
          </SubMenu>
          <MenuItem
            active={isRouteActive('/ci')}
            icon={<Icon icon={faCodeBranch} />}
          >
            CI
            <NavLink to={{ pathname: '/ci' }} exact />
          </MenuItem>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu iconShape="square">
          <AccountButton />
          <MenuItem
            data-testid="sidebar-settings-usage"
            active={isUsageActive}
            icon={<Icon icon={faChartLine} />}
          >
            Usage
            <NavLink to={{ pathname: '/settings/usage', search }} exact />
            {currentOrg?.throttlingStatus !== 'ok' ? (
              <span className={usageIconClassName}>
                &nbsp;&nbsp;
                <Icon icon={faExclamationTriangle} />
              </span>
            ) : null}
          </MenuItem>
          <MenuItem
            data-testid="sidebar-settings"
            active={isSettingsActive}
            icon={<Icon icon={faCog} />}
          >
            Settings
            <NavLink to={{ pathname: '/settings', search }} exact />
          </MenuItem>
          <SupportButton />
          {/* <MenuItem
            onClick={() => redirectToLogout()}
            icon={<Icon icon={faSignOutAlt} />}
          >
            Sign out
          </MenuItem> */}
          <MenuItem
            className={`${styles.collapseIcon} ${
              collapsed ? styles.collapsedIconCollapsed : ''
            }`}
            onClick={toggleCollapse}
            icon={<Icon icon={faChevronLeft} />}
          >
            Collapse Sidebar
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </Sidebar>
  );
}

export default Sidebar2;
