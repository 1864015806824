import { createSlice } from '@reduxjs/toolkit';
import { Invites } from '@cloudstorage/models/invites';
import type { RootState } from '@cloudstorage/redux/store';
import { addNotification } from '@webapp/redux/reducers/notifications';
import { createAsyncThunk } from '@webapp/redux/async-thunk';

import { fetchInvites } from '@cloudstorage/services/invites';

type InvitesState = {
  type: 'pristine' | 'loading' | 'loaded' | 'failed';
  data?: Invites;
};

const invitesInitialState: InvitesState = {
  type: 'pristine',
  data: undefined,
};

export const reloadInvites = createAsyncThunk(
  'newRoot/reloadInvites',
  async (_, thunkAPI) => {
    const res = await fetchInvites();

    if (res.isOk) {
      return Promise.resolve(res.value);
    }

    void thunkAPI.dispatch(
      addNotification({
        type: 'danger',
        title: 'Failed to load invites',
        message: res.error.message,
      })
    );

    return Promise.reject(res.error);
  }
);

export const invitesSlice = createSlice({
  name: 'invites',
  initialState: invitesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reloadInvites.fulfilled, (state, action) => {
      return { type: 'loaded', data: action.payload };
    });

    builder.addCase(reloadInvites.pending, (state) => {
      return { type: 'loading', data: state.data };
    });
    builder.addCase(reloadInvites.rejected, (state) => {
      return { type: 'failed', data: state.data };
    });
  },
});

export const invitesState = (state: RootState) => state.invites;
export const selectInvites = (state: RootState) => state.invites.data;

export default invitesSlice.reducer;
