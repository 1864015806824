import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@webapp/redux/async-thunk';
import type { Organizations } from '../../models/organizations';

import { loadOrganizations as loadOrganizationsAPI } from '../../services/organizations';

import type { RootState } from '../store';

// This was copied from user.ts in pyroscope-oss

interface OrganizationsRootState {
  type: 'loading' | 'loaded' | 'failed';
  data?: Organizations;
}

// Define the initial state using that type
const initialState: OrganizationsRootState = {
  type: 'loading',
  data: undefined,
};

export const loadOrganizations = createAsyncThunk(
  'newRoot/loadOrganizations',
  async () => {
    const res = await loadOrganizationsAPI();
    if (res.isOk) {
      return Promise.resolve(res.value);
    }

    return Promise.reject(res.error);
  }
);

const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadOrganizations.fulfilled, (state, action) => {
      return { type: 'loaded', data: action.payload };
    });
    builder.addCase(loadOrganizations.pending, (state) => {
      return { type: 'loading', data: state.data };
    });
    builder.addCase(loadOrganizations.rejected, (state) => {
      return { type: 'failed', data: state.data };
    });
  },
});

export const OrganizationsState = (state: RootState) => state.organizations;
export const selectOrganizations = (state: RootState) =>
  state.organizations?.data;

export default organizationSlice.reducer;
