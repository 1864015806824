import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistedState } from 'redux-persist/lib/types';
import type { RootState } from '@webapp/redux/store';

// Persistence Migrations
// See examples on https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
export const migrations = {
  0: (state: PersistedState) => {
    if (!state) {
      return {} as PersistedState;
    }

    return { ...state };
  },
};

export const persistConfig = {
  key: 'pyroscope:throttlingNotifications',
  version: 0,
  storage,
  migrate: createMigrate(migrations, { debug: true }),
};

export interface ThrottlingNotificationState {
  lastNotificationDate?: string;
}

const initialState: ThrottlingNotificationState = {
  lastNotificationDate: undefined,
};

export const throttlingNotificationsSlice = createSlice({
  name: 'throttlingNotifications',
  initialState,
  reducers: {
    updateLastNotificationDate: (state, action: PayloadAction<string>) => {
      state.lastNotificationDate = action.payload;
    },
  },
});

const selectThrottlingNotificationsState = (state: RootState) =>
  state.throttlingNotifications;

export const selectLastNotificationDate = createSelector(
  selectThrottlingNotificationsState,
  (state) => state.lastNotificationDate
);

export const { updateLastNotificationDate } =
  throttlingNotificationsSlice.actions;

export default throttlingNotificationsSlice.reducer;
