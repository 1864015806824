import { Application, Branch, Event } from '@cloudstorage/models/ci';
import { Profile } from '@pyroscope/models/src';

type genericState<T> =
  | { type: 'pristine' }
  | { type: 'loading' }
  | {
      type: 'loaded';
      data: T;
    };

export type CIState = {
  // Only branch is required, since application comes from the query
  // Which currently is shared with `continuous`
  selectedBranchName?: string;

  applications: genericState<
    Record<
      string,
      {
        data: Application;
        branches: genericState<Branch[]>;
      }
    >
  >;

  events: genericState<Event[]>;
  selectedEventID?: string;
  //  profile: genericState<Profile>;
  profile:
    | { type: 'pristine' }
    | { type: 'loading'; data?: Profile }
    | {
        type: 'loaded';
        data: Profile;
      };

  disableOnboarding: boolean;
};

export const initialState: CIState = {
  applications: { type: 'pristine' },
  events: { type: 'pristine' },
  selectedBranchName: undefined,
  selectedEventID: undefined,
  profile: { type: 'pristine' },
  disableOnboarding: false,
};
