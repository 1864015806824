import { CollapseBox } from '@webapp/ui/Box';
import TableUI from '@webapp/ui/Table';
import { Event } from '@cloudstorage/models/ci';
import { Maybe } from '@webapp/util/fp';
import { format } from 'date-fns';
import { getFormatter } from '@pyroscope/flamegraph/src/format/format';

interface TestSuiteRunsTableProps {
  selectedCIEvent: Maybe<Event>;
  onSelectedCIEvent: (e: Event) => void;
  ciEvents: Event[];
}
export function TestSuiteRunsTable(props: TestSuiteRunsTableProps) {
  const { ciEvents, selectedCIEvent, onSelectedCIEvent } = props;

  const table = ciEventToTable(ciEvents, selectedCIEvent, onSelectedCIEvent);
  return (
    <CollapseBox title="Test Suite Runs">
      <TableUI
        itemsPerPage={5}
        table={{
          headRow: table.headRow,
          bodyRows: table.rows,
          type: 'filled',
        }}
      />
    </CollapseBox>
  );
}

function ciEventToRow(
  selected: Maybe<Event>,
  event: Event,
  onClick: (event: Event) => void
) {
  // TODO: why does sampleRate needs to be passed twice?
  const formatter = getFormatter(event.value, event.sampleRate, event.units);
  const formattedValue = formatter.format(event.value, event.sampleRate);

  const date = new Date(event.date);
  const timeString = `${format(date, 'MMM d, yyyy')} at ${format(
    date,
    'h:mm:ss a'
  )}`;

  return {
    isRowSelected: selected.isJust && selected.value === event,
    isRowDisabled: false,
    onClick: () => onClick(event),
    cells: [
      { value: event.duration },
      { value: formattedValue },
      { value: event.commitSHA.slice(0, 7) },
      { value: timeString },
    ],
  };
}

function ciEventToTable(
  ciEvents: Event[],
  selectedCIEvent: Maybe<Event>,
  onSelectedCIEvent: (e: Event) => void
) {
  // TODO(eh-am): sortable doesn't seem to be working
  const headRow = [
    { name: '', label: 'Duration', sortable: 0 },
    { name: '', label: 'Value', sortable: 0 },
    { name: '', label: 'Commit SHA', sortable: 0 },
    { name: '', label: 'Date', sortable: 1 },
  ];

  const rows = ciEvents.map((v) =>
    ciEventToRow(selectedCIEvent, v, onSelectedCIEvent)
  );

  return {
    headRow,
    rows,
  };
}
