import { useFlags } from 'launchdarkly-react-client-sdk';

import Button from '@webapp/ui/Button';
import Icon from '@webapp/ui/Icon';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { useEffect, useState } from 'react';

import { request } from '@webapp/services/base';
import ModalActions from './ModalActions';
import Modal from './Modal';
import FullPageSpinner from './FullPageSpinner';

import styles from './TermsWall.module.css';

type consentType = {
  agreedToTermsAndPrivacyPolicy: boolean;
};

export default function TermsWall({ children }: { children: React.ReactNode }) {
  const { customTerms } = useFlags();

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [termsOn, setTermsOn] = useState(false);
  const [privacyOn, setPrivacyOn] = useState(false);

  const getConsent = async () => {
    const response = await request(`/api/consent`, {
      method: 'GET',
    });

    if (response.isOk) {
      setAgreedToTerms(
        (response.value as consentType).agreedToTermsAndPrivacyPolicy
      );
    } else {
      // eslint-disable-next-line no-console
      console.error('Request to /api/consent failed');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!customTerms) {
      void getConsent();
    }
  }, [customTerms]);

  const updateConsent = async () => {
    if (!(termsOn && privacyOn)) {
      return;
    }

    await request(`/api/consent`, {
      method: 'POST',
    });

    window.document.location.reload();
  };

  const shouldShowTerms = !(customTerms || agreedToTerms);

  if (!customTerms && isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      {children}
      {shouldShowTerms ? (
        <Modal title="Service Agreements">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              void updateConsent();
            }}
          >
            <p>
              Before using Pyroscope Cloud, please agree to our Terms of Service
              and Privacy Policy.
            </p>
            <ul>
              <li className={styles.item}>
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsOn}
                  onChange={(e) => setTermsOn(e.target.checked)}
                />
                &nbsp;
                <label htmlFor="terms">
                  I agree to the{' '}
                  <a
                    className={styles.link}
                    href="https://pyroscope.io/terms/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Terms of Service <Icon icon={faExternalLinkAlt} />
                  </a>
                </label>
              </li>
              <li className={styles.item}>
                <input
                  type="checkbox"
                  id="privacy"
                  checked={privacyOn}
                  onChange={(e) => setPrivacyOn(e.target.checked)}
                />
                &nbsp;
                <label htmlFor="privacy">
                  I agree to the{' '}
                  <a
                    className={styles.link}
                    href="https://pyroscope.io/privacy/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Privacy Policy <Icon icon={faExternalLinkAlt} />
                  </a>
                </label>
              </li>
            </ul>
            <ModalActions>
              <Button
                type="submit"
                kind={termsOn && privacyOn ? 'secondary' : 'default'}
                disabled={!(termsOn && privacyOn)}
              >
                Continue
              </Button>
            </ModalActions>
          </form>
        </Modal>
      ) : null}
    </>
  );
}
