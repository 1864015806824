import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Until we rewrite FlamegraphRenderer in typescript this will do
import ReduxQuerySync from 'redux-query-sync';
import { configureStore, combineReducers, Middleware } from '@reduxjs/toolkit';

import history from '@webapp/util/history';

import settingsReducer from '@webapp/redux/reducers/settings';
import userReducer from '@webapp/redux/reducers/user';
import userExtrasReducer from '@cloudstorage/redux/reducers/userExtras';
import continuousReducer, {
  actions as continuousActions,
} from '@webapp/redux/reducers/continuous';
import tracingReducer, {
  actions as tracingActions,
} from '@webapp/redux/reducers/tracing';
import { actions as ciActions } from '@cloudstorage/redux/reducers/ci';
import serviceDiscoveryReducer from '@webapp/redux/reducers/serviceDiscovery';
import adhocReducer from '@webapp/redux/reducers/adhoc';
import uiStore, {
  persistConfig as uiPersistConfig,
} from '@webapp/redux/reducers/ui';
import throttlingNotificationsStore, {
  persistConfig as throttlingNotificationsPersistConfig,
} from './reducers/throttlingNotifications';
import organizationsReducer from './reducers/organizations';
import organizationReducer from './reducers/organization';
import plansReducer from './reducers/plans';
import invitesReducer from './reducers/invites';
import ciReducer, { ciPersistConfig } from './reducers/ci';

const reducer = combineReducers({
  settings: settingsReducer,
  invites: invitesReducer,
  user: userReducer,
  userExtras: userExtrasReducer,
  organization: organizationReducer,
  organizations: organizationsReducer,
  plans: plansReducer,
  serviceDiscovery: serviceDiscoveryReducer,
  ui: persistReducer(uiPersistConfig, uiStore),
  throttlingNotifications: persistReducer(
    throttlingNotificationsPersistConfig,
    throttlingNotificationsStore
  ),
  continuous: continuousReducer,
  tracing: tracingReducer,
  adhoc: adhocReducer,
  ci: persistReducer(ciPersistConfig, ciReducer),
});

function isError(action: unknown): action is { error: unknown } {
  return (action as { error: string }).error !== undefined;
}

// Most times we will display a (somewhat) user friendly message toast
// But it's still useful to have the actual error logged to the console
export const logErrorMiddleware: Middleware = () => (next) => (action) => {
  next(action);
  if (isError(action)) {
    // eslint-disable-next-line no-console
    console.error(action.error);
  }
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['error'],

        // Based on this issue: https://github.com/rt2zz/redux-persist/issues/988
        // and this guide https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([logErrorMiddleware]),
});

export const persistor = persistStore(store);

// This is a bi-directional sync between the query parameters and the redux store
// It works as follows:
// * When URL query changes, It will dispatch the action
// * When the store changes (the field set in selector), the query param is updated
// For more info see the implementation at
// https://github.com/Treora/redux-query-sync/blob/master/src/redux-query-sync.js
ReduxQuerySync({
  store,
  params: {
    from: {
      defaultValue: 'now-1h',
      selector: (state: RootState) => state.continuous.from,
      action: continuousActions.setFrom,
    },
    until: {
      defaultValue: 'now',
      selector: (state: RootState) => state.continuous.until,
      action: continuousActions.setUntil,
    },
    leftFrom: {
      defaultValue: 'now-1h',
      selector: (state: RootState) => state.continuous.leftFrom,
      action: continuousActions.setLeftFrom,
    },
    leftUntil: {
      defaultValue: 'now-30m',
      selector: (state: RootState) => state.continuous.leftUntil,
      action: continuousActions.setLeftUntil,
    },
    rightFrom: {
      defaultValue: 'now-30m',
      selector: (state: RootState) => state.continuous.rightFrom,
      action: continuousActions.setRightFrom,
    },
    rightUntil: {
      defaultValue: 'now',
      selector: (state: RootState) => state.continuous.rightUntil,
      action: continuousActions.setRightUntil,
    },
    query: {
      defaultvalue: '',
      selector: (state: RootState) => state.continuous.query,
      action: continuousActions.setQuery,
    },
    queryID: {
      defaultvalue: '',
      selector: (state: RootState) => state.tracing.queryID,
      action: tracingActions.setQueryID,
    },
    rightQuery: {
      defaultvalue: '',
      selector: (state: RootState) => state.continuous.rightQuery,
      action: continuousActions.setRightQuery,
    },
    leftQuery: {
      defaultvalue: '',
      selector: (state: RootState) => state.continuous.leftQuery,
      action: continuousActions.setLeftQuery,
    },
    maxNodes: {
      defaultValue: '0',
      selector: (state: RootState) => state.continuous.maxNodes,
      action: continuousActions.setMaxNodes,
    },
    groupBy: {
      defaultValue: '',
      selector: (state: RootState) =>
        state.continuous.tagExplorerView.groupByTag,
      action: continuousActions.setTagExplorerViewGroupByTag,
    },
    groupByValue: {
      defaultValue: '',
      selector: (state: RootState) =>
        state.continuous.tagExplorerView.groupByTagValue,
      action: continuousActions.setTagExplorerViewGroupByTagValue,
    },

    // Used in CI
    branch: {
      defaultValue: undefined,
      selector: (state: RootState) => state.ci.selectedBranchName,
      action: ciActions.setSelectedBranch,
    },

    ciEvent: {
      defaultValue: undefined,
      selector: (state: RootState) => state.ci.selectedEventID,
      action: ciActions.setSelectedEventID,
    },
  },
  initialTruth: 'location',
  replaceState: false,
  history,
});
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
