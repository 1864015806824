import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import cl from 'classnames';

import TableUI from '@webapp/ui/Table';
import { useAppDispatch } from '@webapp/redux/hooks';
import { useAppSelector } from '@cloudstorage/redux/hooks';
import {
  reloadInvites,
  selectInvites,
} from '@cloudstorage/redux/reducers/invites';
import { selectCurrentUser } from '@webapp/redux/reducers/user';
import InviteMembersModal from '@cloudstorage/components/InviteMembersModal';

import Button from '@webapp/ui/Button';

import tableStyles from '@webapp/components/Settings/SettingsTable.module.scss';
import userStyles from './Invites.module.css';
import { getInviteTableRows } from './getInviteTableRows';

const headRow = [
  { name: '', label: 'Email', sortable: 0 },
  { name: '', label: 'Invited By', sortable: 0 },
  { name: '', label: 'Created', sortable: 0 },
  { name: '', label: 'Expires', sortable: 0 },
];

function Invites() {
  const dispatch = useAppDispatch();
  const invites = useAppSelector(selectInvites);
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    async function loadInvites() {
      await dispatch(reloadInvites()).unwrap();
    }

    if (!invites) {
      void loadInvites();
    }
  }, [invites, dispatch]);

  const displayInvites = invites || [];

  const [inviteMemberModal, setInviteMemberModal] = useState(false);
  const inviteMembersModal = () => {
    setInviteMemberModal(true);
  };

  if (!currentUser) return null;

  const tableBodyProps =
    displayInvites.length > 0
      ? {
          bodyRows: getInviteTableRows(displayInvites),
          type: 'filled' as const,
        }
      : {
          type: 'not-filled' as const,
          value: 'The list is empty',
          bodyClassName: userStyles.invitesTableEmptyMessage,
        };

  return (
    <>
      <h2>Invitations</h2>
      <p>
        <Button
          kind="secondary"
          icon={faPlus}
          onClick={() => inviteMembersModal()}
        >
          Invite Members
        </Button>
      </p>
      {inviteMemberModal ? (
        <InviteMembersModal onClose={() => setInviteMemberModal(false)} />
      ) : null}
      <TableUI
        className={cl(userStyles.invitesTable, tableStyles.settingsTable)}
        table={{ headRow, ...tableBodyProps }}
      />
    </>
  );
}

export default Invites;
