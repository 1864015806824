import { useEffect } from 'react';
import { addNotification } from '@webapp/redux/reducers/notifications';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectLastNotificationDate,
  updateLastNotificationDate,
} from '../redux/reducers/throttlingNotifications';
import { selectCurrentOrganization } from '../redux/reducers/organization';

function now() {
  return new Date().toDateString();
}

export default function ThrottlingNotifications() {
  const dispatch = useAppDispatch();
  const currentOrg = useAppSelector(selectCurrentOrganization);
  const dateLastNotification = useAppSelector(selectLastNotificationDate);
  const dateCurrent = now();
  const { throttlingNotificationsEnabledFrontend } = useFlags();
  useEffect(() => {
    if (!throttlingNotificationsEnabledFrontend) {
      return;
    }
    if (
      currentOrg?.throttlingStatus === 'throttled' &&
      dateLastNotification !== dateCurrent
    ) {
      dispatch(updateLastNotificationDate(dateCurrent));
      void dispatch(
        addNotification({
          type: 'danger',
          title: 'Your profiles are being dropped due to throttling.',
          message:
            'Your organization has exceeded your monthly profiling quota, so the profiles you' +
            ' are sending to Pyroscope Cloud are being throttled. ' +
            'Some profiles will continue to be saved with some gaps, but you will not have a complete' +
            " picture of your application. Check your organization's monthly usage.",
        })
      );
    }
  });
  return null;
}
