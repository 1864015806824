import { Maybe } from '@webapp/util/fp';
import AppSelector from '@webapp/components/AppSelector';
import { Application, Branch } from '@cloudstorage/models/ci';
import Dropdown, { MenuItem } from '@webapp/ui/Dropdown';
import { ClickEvent } from '@szhsin/react-menu';
import styles from './AppBranchSelector.module.scss';

interface AppBranchSelectorProps {
  selectedApp: Maybe<Application>;
  selectedBranch: Maybe<Branch>;
  onSelectedApp: (a: string) => void;
  onSelectedBranch: (b: string) => void;
  apps: Application[];
  branches: Branch[];
}

// Notice that it may be possible to have inconsistent states.
// For example, a branch belonging to an application without that branch
export function AppBranchSelector(props: AppBranchSelectorProps) {
  const {
    apps,
    selectedApp,
    branches,
    selectedBranch,
    onSelectedBranch,
    onSelectedApp,
  } = props;

  return (
    <div className={styles.container}>
      <AppSelector
        onSelected={onSelectedApp}
        apps={apps}
        selectedAppName={selectedApp.map((a) => a.name).unwrapOr('')}
      />
      <BranchSelector
        onSelectedBranch={onSelectedBranch}
        selectedBranch={selectedBranch}
        branches={branches}
      />
    </div>
  );
}

interface BranchSelectorProps {
  branches: Branch[];
  selectedBranch: Maybe<Branch>;
  onSelectedBranch: (b: string) => void;
}
function BranchSelector({
  branches,
  selectedBranch,
  onSelectedBranch,
}: BranchSelectorProps) {
  const branchesMenuItem = branches.map((b) => {
    return (
      <MenuItem key={b.name} value={b.name}>
        {b.name}
      </MenuItem>
    );
  });

  return (
    <Dropdown
      label="Select a branch"
      value={selectedBranch.map((a) => a.name).unwrapOr(undefined)}
      onItemClick={(e: ClickEvent) => {
        onSelectedBranch(e.value);
      }}
    >
      {branchesMenuItem}
    </Dropdown>
  );
}
