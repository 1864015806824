import Button from '@webapp/ui/Button';
import InputField from '@webapp/ui/InputField';
import LoadingSpinner from '@webapp/ui/LoadingSpinner';
import { useState } from 'react';
import { Organization } from '../models/organizations';
import { createOrganization as createOrganizationAPI } from '../services/organizations';
import ModalActions from './ModalActions';
import styles from './NewOrganizationForm.module.css';

export default function NewOrganizationForm() {
  const [form, setForm] = useState({
    displayName: '',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      ...form,
    } as Organization;

    setIsLoading(true);

    const res = await createOrganizationAPI(data);
    if (res.isOk) {
      document.location.reload();
    } else {
      setError(res.error.message);
    }
    setIsLoading(false);
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const { value } = event.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          void handleFormSubmit(e);
        }}
      >
        <p className={styles.description}>
          Organizations gather people working together. <br /> If you want to
          join an existing organization, ask organization admin to invite you.
        </p>
        {error ? <div className={styles.errors}>{error}</div> : null}
        <InputField
          label="Name"
          id="userAddName"
          name="displayName"
          type="text"
          autoFocus
          value={form.displayName}
          onChange={handleFormChange}
          className={styles.input}
        />
        <ModalActions>
          {isLoading ? <LoadingSpinner /> : null}
          &nbsp; &nbsp;
          <Button type="submit" kind="secondary" disabled={isLoading}>
            Create organization
          </Button>
        </ModalActions>
      </form>
    </>
  );
}
