import type { RootState } from '@cloudstorage/redux/store';
import { Maybe } from '@webapp/util/fp';
import { Application, Branch, Event } from '@cloudstorage/models/ci';
import { Profile } from '@pyroscope/models/src';
import { brandQuery, queryToAppName } from '@webapp/models/query';
import { CIState } from './state';

export const selectAppsState = (state: RootState) => {
  return state.ci.applications;
};

export const selectApplications = (state: RootState) => {
  if (state.ci.applications.type !== 'loaded') {
    return [];
  }

  return Object.values(state.ci.applications.data).map((a) => a.data);
};

export const selectBranches =
  (app: Maybe<Application>) => (state: RootState) => {
    if (app.isNothing) {
      return [];
    }

    const appName = app.value.name;

    // Haven't loaded any applications
    if (state.ci.applications.type !== 'loaded') {
      return [];
    }

    // We may be trying to access an application that wasn't loaded
    //    if (!state.ci.applications.data[appName]) {
    //      return [];
    //    }

    const branchesState = state.ci.applications.data[appName].branches;

    if (branchesState.type !== 'loaded') {
      return [];
    }

    return branchesState.data;
  };

// Selects the entire app substate for a selected app
export const selectSelectedAppState = (state: RootState) => {
  type LoadedState = Extract<
    CIState['applications'],
    { type: 'loaded' }
  >['data'][string];

  // TODO: right now we are assuming it's coming from 'query'
  // TODO: use a selector to query other 'queries', like 'leftQuery' and 'rightQuery'
  const appName = queryToAppName(brandQuery(state.continuous.query));
  const appState = state.ci.applications;

  // Invalid app name
  if (appName.isNothing) {
    return Maybe.nothing<LoadedState>();
  }

  // Apps haven't been loaded
  if (appState.type !== 'loaded') {
    return Maybe.nothing<LoadedState>();
  }

  return appName.andThen((s) => {
    const exists = Object.values(appState.data).find((a) => {
      return a.data.name === s;
    });

    return Maybe.of(exists);
  });
};

export const selectSelectedApp = (state: RootState) => {
  return selectSelectedAppState(state).map((a) => a.data);
};

export const selectSelectedBranch = (state: RootState) => {
  const selectedAppState = selectSelectedAppState(state);

  return selectedAppState.andThen((a) => {
    // Branches haven't been loaded, therefore there's no selectedbranch
    if (a.branches.type !== 'loaded') {
      return Maybe.nothing<Branch>();
    }

    // Does the selectedBranch match the list of branches?
    return Maybe.of(
      a.branches.data.find((a) => a.name === state.ci.selectedBranchName)
    );
  });
};

export const selectEvents = (state: RootState) => {
  return (() => {
    switch (state.ci.events.type) {
      case 'pristine': {
        return [];
      }

      case 'loading': {
        return [];
      }

      case 'loaded': {
        return state.ci.events.data;
      }

      default: {
        const exhaustiveCheck: never = state.ci.events;
        return exhaustiveCheck;
      }
    }
  })();
};

export const selectSelectedEvent = (state: RootState) => {
  const events = selectEvents(state);

  if (!state.ci.selectedEventID) {
    return Maybe.nothing<Event>();
  }

  return Maybe.of(events.find((a) => a.id === state.ci.selectedEventID));
};

export const selectSelectedEventProfile = (state: RootState) => {
  // If the selected event is pointing to an outdated event,
  // it means we don't have anything selected
  if (selectSelectedEvent(state).isNothing) {
    return Maybe.nothing<Profile>();
  }

  // TODO: do I need to check that profile refers to an existing profile?
  return (() => {
    switch (state.ci.profile.type) {
      case 'pristine': {
        return Maybe.nothing<Profile>();
      }

      case 'loading': {
        return Maybe.nothing<Profile>();
      }

      case 'loaded': {
        return Maybe.of(state.ci.profile.data);
      }

      default: {
        const exhaustiveCheck: never = state.ci.profile;
        return exhaustiveCheck;
      }
    }
  })();
};

// If we have loaded both the list of apps and the list of branches
// And the selectedBranch does not match the list of branches we have
// It means we are in an inconsistent state
export function selectIsInconsistent(
  state: RootState
): false | { reason: 'application' | 'branch' } {
  // List of applications have not been loaded
  if (state.ci.applications.type !== 'loaded') {
    return false;
  }

  const appState = selectSelectedAppState(state);
  // The application we have is not valid
  if (appState.isNothing) {
    return { reason: 'application' };
  }

  // Branches have not been loaded yet, so we can't tell anything about branches
  if (appState.value.branches.type !== 'loaded') {
    return false;
  }

  const selectedBranch = selectSelectedBranch(state);
  // Branches have been loaded, but the branch we have is not on the list
  if (selectedBranch.isNothing) {
    return { reason: 'branch' };
  }

  return false;
}

export function selectIsOnboardRequired(state: RootState) {
  // User has clicked to never show the box again
  if (state.ci.disableOnboarding) {
    return false;
  }

  // No applications have been loaded, we can't tell yet
  if (state.ci.applications.type !== 'loaded') {
    return false;
  }

  // There's more than one application, we can assume user knows what she's doing
  if (
    state.ci.applications.type === 'loaded' &&
    Object.keys(state.ci.applications.data).length > 0
  ) {
    return false;
  }

  return true;
}
