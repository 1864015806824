import 'react-dom';
import { useState, useEffect } from 'react';
import { FlamegraphRenderer } from '@pyroscope/flamegraph/src/FlamegraphRenderer';
import useColorMode from '@webapp/hooks/colorMode.hook';
import Box, { CollapseBox } from '@webapp/ui/Box';
import { Event } from '@cloudstorage/models/ci';
import { useAppDispatch, useAppSelector } from '@cloudstorage/redux/hooks';
import {
  actions as continuousActions,
  selectRanges,
  setDateRange,
} from '@webapp/redux/reducers/continuous';
import { actions } from '@cloudstorage/redux/reducers/ci';
import {
  selectApplications,
  selectSelectedApp,
  selectSelectedBranch,
  selectEvents,
  selectSelectedEvent,
  selectBranches,
  selectIsOnboardRequired,
} from '@cloudstorage/redux/reducers/ci/selectors';
import { CIState } from '@cloudstorage/redux/reducers/ci/state';
import Button from '@webapp/ui/Button';
import { LoadingOverlay } from '@webapp/ui/LoadingOverlay';
import { Maybe } from '@webapp/util/fp';
import ExportData from '@webapp/components/ExportData';
import useExportToFlamegraphDotCom from '@webapp/components/exportToFlamegraphDotCom.hook';
import { queryFromAppName } from '@webapp/models/query';
import DateRangePicker from '@webapp/components/DateRangePicker';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@webapp/ui/Dialog';
import { formatAsOBject } from '@webapp/util/formatDate';
import { TestSuiteRunsTable } from './CI/TestSuiteRunsTable';
import { AppBranchSelector } from './CI/AppBranchSelector';
import { useDataLoader } from './CI/useDataLoader.hook';
import { Timeline } from './CI/Timeline';
import styles from './CI/styles.module.css';

function OnboardingModal() {
  const dispatch = useAppDispatch();
  const [isDialogOpen, setDialogOpen] = useState(true);

  const onClose = () => {
    setDialogOpen(false);
  };

  const onDismissForever = () => {
    setDialogOpen(false);
    void dispatch(actions.disableOnboarding());
  };

  return (
    <>
      <Dialog open={isDialogOpen} aria-labelledby="onboarding-dialog-header">
        <>
          <DialogHeader closeable onClose={onClose}>
            <h3 id="onboarding-dialog-header">CI Onboarding</h3>
          </DialogHeader>
          <DialogBody>
            <p>
              Welcome! This is a new feature that allows you to profile your CI
              tests.
            </p>
            <p>
              For more information on how to integrate it into your pipeline,
              <Button
                kind="secondary"
                onClick={() =>
                  window.open('https://pyroscope.io/docs/ci', '_blank')
                }
              >
                Open the Documentation
              </Button>
            </p>

            <p>Happy profiling!</p>
          </DialogBody>
          <DialogFooter>
            <div className={styles.dialogFooter}>
              <Button kind="default" onClick={onDismissForever}>
                Don&apos;t show this again
              </Button>
              <Button kind="secondary" onClick={onClose}>
                Ok
              </Button>
            </div>
          </DialogFooter>
        </>
      </Dialog>
    </>
  );
}

export function CIPage() {
  const dispatch = useAppDispatch();
  const selectedApp = useAppSelector(selectSelectedApp);
  const selectedBranch = useAppSelector(selectSelectedBranch);
  const apps = useAppSelector(selectApplications);
  const profileState = useAppSelector((state) => state.ci.profile);
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const events = useAppSelector(selectEvents);
  const branches = useAppSelector(selectBranches(selectedApp));
  const isOnboardRequired = useAppSelector(selectIsOnboardRequired);

  // TODO: clena this up
  const { regular } = useAppSelector(selectRanges);
  const from = formatAsOBject(regular.from);
  const until = formatAsOBject(regular.until);

  useDataLoader();

  // When first loading, use 7 days
  useEffect(() => {
    dispatch(setDateRange({ from: 'now-7d', until: 'now' }));
  }, []);

  return (
    <>
      {isOnboardRequired && <OnboardingModal />}
      <div style={{ marginTop: '10px', marginBottom: '10px', display: 'flex' }}>
        <AppBranchSelector
          apps={apps}
          branches={branches}
          selectedApp={selectedApp}
          selectedBranch={selectedBranch}
          onSelectedApp={(appName) => {
            void dispatch(
              continuousActions.setQuery(queryFromAppName(appName))
            );
          }}
          onSelectedBranch={(branchName) => {
            void dispatch(actions.setSelectedBranch(branchName));
          }}
        />
        <div style={{ display: 'flex', flex: 1 }} />
        <DateRangePicker />
      </div>
      <TimelineAndTable
        from={from}
        to={until}
        ciEvents={events}
        selectedCIEvent={selectedEvent}
        onSelectedCIEvent={(e: Event) => {
          void dispatch(actions.toggleSelectedEvent(e.id));
        }}
        onTimeRangeChanged={(from, to) => {
          void dispatch(
            continuousActions.setDateRange({
              from: from.toString(),
              until: to.toString(),
            })
          );
        }}
      />
      <FlamegraphSection state={profileState} />
    </>
  );
}

function FlamegraphSection({ state }: { state: CIState['profile'] }) {
  const { colorMode } = useColorMode();
  const p = 'data' in state ? state.data : undefined;

  const exportToFlamegraphDotComFn = useExportToFlamegraphDotCom(p);

  const exportData = p ? (
    <ExportData
      flamebearer={p}
      exportPNG
      exportFlamegraphDotCom
      exportFlamegraphDotComFn={exportToFlamegraphDotComFn}
    />
  ) : (
    <></>
  );

  return (
    <CollapseBox title="Flamegraph">
      <LoadingOverlay
        active={state.type === 'loading'}
        spinnerPosition="baseline"
      >
        <FlamegraphRenderer
          showCredit={false}
          profile={p}
          colorMode={colorMode}
          ExportData={exportData}
        />
      </LoadingOverlay>
    </CollapseBox>
  );
}

function TimelineAndTable({
  selectedCIEvent,
  onSelectedCIEvent,
  ciEvents,
  onTimeRangeChanged,
  from,
  to,
}: {
  selectedCIEvent: Maybe<Event>;
  onSelectedCIEvent: (e: Event) => void;
  onTimeRangeChanged: React.ComponentProps<
    typeof Timeline
  >['onTimeRangeChanged'];
  from: React.ComponentProps<typeof Timeline>['from'];
  to: React.ComponentProps<typeof Timeline>['to'];
  ciEvents: Event[];
}) {
  return (
    <>
      <Box>
        <Timeline
          from={from}
          to={to}
          ciEvents={ciEvents}
          onSelectedCIEvent={onSelectedCIEvent}
          onTimeRangeChanged={onTimeRangeChanged}
        />
      </Box>
      <TestSuiteRunsTable
        ciEvents={ciEvents}
        selectedCIEvent={selectedCIEvent}
        onSelectedCIEvent={onSelectedCIEvent}
      />
    </>
  );
}
