import Button from 'pyroscope-oss/webapp/javascript/ui/Button';
import InputField from 'pyroscope-oss/webapp/javascript/ui/InputField';
import { useState } from 'react';
import LoadingSpinner from 'pyroscope-oss/webapp/javascript/ui/LoadingSpinner';
import { selectCurrentOrganization } from '../redux/reducers/organization';
import { inviteUser as inviteUserAPI } from '../services/organizations';
import Modal from './Modal';
import ModalActions from './ModalActions';
import { useAppSelector } from '../redux/hooks';

import styles from './InviteMembersModal.module.css';
// import { closeModal } from '../redux/reducers/ui';

export default function InviteMembersModal({
  onClose,
}: {
  onClose?: () => void;
}) {
  const organization = useAppSelector(selectCurrentOrganization);
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');
  const handleInviteMembers = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus('loading');
    const f = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
    };

    async function run() {
      const x = await inviteUserAPI(f.email.value);
      if (x.isOk && x.value) {
        setMessage('Invite sent');
        setError('');
        setStatus('loaded');
        setEmailAddress('');

        // TODO(petethepig): need to focus on the input too
      } else {
        if (x.isErr) {
          setError(x.error.message);
        } else {
          setError(`Server responded with ${x.value}`);
        }

        setMessage('');
        setStatus('error');
      }
    }

    void run();
  };

  return (
    <Modal
      title={`Inviting team members to ${organization?.displayName}`}
      onClose={onClose}
      closable
    >
      <form onSubmit={handleInviteMembers}>
        <p>
          An invite is <strong>specific to an email address</strong> and{' '}
          <strong>expires after 7 days</strong>.
        </p>
        {error ? <div className={styles.errors}>{error}</div> : null}
        <div>
          <InputField
            type="text"
            name="email"
            label="Email address"
            value={emailAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmailAddress(e.target.value);
            }}
            placeholder="emily@pyroscope.io"
          />
        </div>
        <ModalActions>
          <span style={{ float: 'left' }}>{message}</span>
          {status === 'loading' ? <LoadingSpinner /> : null}
          &nbsp; &nbsp;
          <Button kind="secondary" type="submit">
            Invite Team Member
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
}
