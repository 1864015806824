// TODO maybe the location will be different if the backend is served under a different domain
export function redirectToLogin() {
  if (window && window.location) {
    window.location.href = '/login';
  }
}

export function redirectToLogout() {
  if (window && window.location) {
    window.location.href = '/logout';
  }
}
