import { Result } from '@webapp/util/fp';
import type { ZodError } from 'zod';
import { request, parseResponse } from '@webapp/services/base';
import type { RequestError } from '@webapp/services/base';
import { type Plan, Plans, plansModel } from '../models/plans';

export async function loadPlans(): Promise<
  Result<Plans, RequestError | ZodError>
> {
  const response = await request(`/api/plans`);
  if (response.isOk) {
    return parseResponse(response, plansModel);
  }

  return Result.err<Plans, RequestError>(response.error);
}

export async function switchPlan(
  plan: Plan
): Promise<Result<boolean, RequestError | ZodError>> {
  const response = await request(`/api/plans`, {
    method: 'POST',
    body: JSON.stringify(plan),
  });

  if (response.isOk) {
    return Result.ok(true);
  }

  return Result.err<false, RequestError>(response.error);
}
