import { z } from 'zod';

export const planModel = z.object({
  id: z.string(),
  name: z.string(),
  usingBytes: z.boolean(),
  limit: z.number(),
  price: z.number(),
  retention: z.number(),
});

export const plansModel = z.array(planModel);

export type Plans = z.infer<typeof plansModel>;
export type Plan = z.infer<typeof planModel>;
