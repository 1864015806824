import { Result } from '@webapp/util/fp';
import {
  type UserExtras,
  userExtrasModel,
} from '@cloudstorage/models/userExtras';

import type { ZodError } from 'zod';
import { modelToResult } from '@webapp/models/utils';
import { request } from '@webapp/services/base';
import type { RequestError } from '@webapp/services/base';

export interface FetchUsersError {
  message?: string;
}

export async function loadCurrentUserExtras(): Promise<
  Result<UserExtras, RequestError | ZodError>
> {
  const response = await request(`/api/user/extras`);
  if (response.isOk) {
    return modelToResult<UserExtras>(userExtrasModel, response.value);
  }

  return Result.err<UserExtras, RequestError>(response.error);
}
