import Icon, { IconProps } from '@webapp/ui/Icon';
import Button from '@webapp/ui/Button';
import { useHistory } from 'react-router-dom';

import styles from './MenuItemWithButton.module.css';

export default function MenuItemWithButton({
  title,
  icon,
  route,
}: {
  title: React.ReactNode;
  icon: IconProps['icon'];
  route: string;
}) {
  const history = useHistory();
  return (
    <div className={styles.menuItemWithButton}>
      <span className={styles.menuItemWithButtonTitle}>{title}</span>
      <Button className={styles.menuItemWithButtonButton}>
        <div onClick={() => history.push(route)}>
          <Icon icon={icon} />
        </div>
      </Button>
    </div>
  );
}
