import { useState } from 'react';
import styles from './Modal.module.css';

export default function Modal({
  title,
  children,
  closable,
  onClose,
}: {
  title: string;
  children: React.ReactNode;
  closable?: boolean;
  onClose?: () => void;
}) {
  const [closed, setClosed] = useState(false);

  function handleClose() {
    setClosed(true);
    if (onClose) {
      onClose();
    }
  }

  return closed ? null : (
    <div className={styles.modal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h3 className={styles.modalHeading}>{title}</h3>
          {closable ? (
            <button
              aria-label="Close"
              className={styles.closeButton}
              onClick={handleClose}
            />
          ) : null}
        </div>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  );
}
