import {
  FlamebearerProfileSchema,
  SpyNameSchema,
  UnitsSchema,
} from '@pyroscope/models/src';
import { z } from 'zod';

export const applicationSchema = z.object({
  name: z.string(),
});
export type Application = z.infer<typeof applicationSchema>;

export const branchSchema = z.object({
  name: z.string(),
  // TODO: convert to date
  updatedAt: z.string(),
});
export type Branch = z.infer<typeof branchSchema>;

export const eventSchema = z.object({
  id: z.string(),
  name: z.string(),
  branch: z.string(),
  value: z.number().positive(),
  commitSHA: z.string(),
  spyName: SpyNameSchema,
  units: UnitsSchema,
  sampleRate: z.number().default(100),
  // It uses golang's stringified format
  // Since we are just displaying it as is, there's no need to parse
  duration: z.string().transform((s) => {
    // Golang's 0 value
    if (s === '0s') {
      return 'N/A';
    }
    return s;
  }),

  // Since this data is serialized in the redux store, we can't have a Date object since it's non serializable
  date: z.string(),
});

export type Event = z.infer<typeof eventSchema>;

export const eventProfileSchema = z.object({
  profile: FlamebearerProfileSchema,
});

export type EventProfile = z.infer<typeof eventProfileSchema>;
