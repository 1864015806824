import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@cloudstorage/redux/hooks';
import {
  selectSelectedApp,
  selectSelectedBranch,
  selectSelectedEvent,
  selectIsInconsistent,
} from '@cloudstorage/redux/reducers/ci/selectors';
import { actions } from '@cloudstorage/redux/reducers/ci';
import * as thunks from '@cloudstorage/redux/reducers/ci/thunks';

export function useDataLoader() {
  const dispatch = useAppDispatch();

  const { from, until } = useAppSelector((state) => state.continuous);
  const isInconsistent = useAppSelector(selectIsInconsistent);
  const selectedApp = useAppSelector(selectSelectedApp);
  const selectedBranch = useAppSelector(selectSelectedBranch);
  const selectedEvent = useAppSelector(selectSelectedEvent);

  // Load applications when component first renders
  useEffect(() => {
    void dispatch(thunks.loadApplicationsThunk());
  }, []);

  // When app is changed, load its branches
  useEffect(() => {
    if (selectedApp.isJust) {
      void dispatch(thunks.loadBranchesThunk(selectedApp.value));
    }
  }, [JSON.stringify(selectedApp.toJSON())]);

  // Load all events
  useEffect(() => {
    if (from && until && selectedApp.isJust && selectedBranch.isJust) {
      void dispatch(
        thunks.loadEventsThunk({
          app: selectedApp.value,
          branch: selectedBranch.value,
          from,
          until,
        })
      );
    }
  }, [
    from,
    until,
    JSON.stringify(selectedApp.toJSON()),
    JSON.stringify(selectedBranch.toJSON()),
  ]);

  // Load the profile when an event is selected
  useEffect(() => {
    if (selectedEvent.isJust) {
      void dispatch(thunks.loadEventProfileThunk(selectedEvent.value));
    }
  }, [JSON.stringify(selectedEvent.toJSON())]);

  // Handle inconsistencies, since app and branch have different dropdowns
  // Keep in mind this solution breaks the browser navigation, since inconsistent
  // states will be pushed to the history
  useEffect(() => {
    if (isInconsistent) {
      switch (isInconsistent.reason) {
        case 'application': {
          // The application in the query refers to an invalid application
          // We can't clear the query due to a data race in the ReduxQuerySync lib
          // For example, imagine an url with query set to an invalid app
          // If we try to clear that query using action, the lib would try to set it back again
          // Producing an infinite loop
          void dispatch(actions.clearEvents());
          break;
        }
        case 'branch': {
          void dispatch(actions.setSelectedBranch(undefined));
          void dispatch(actions.clearEvents());
          break;
        }

        default: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const exhaustiveCheck: never = isInconsistent.reason;
          break;
        }
      }
    }
  }, [isInconsistent]);
}
