import { Result } from '@webapp/util/fp';
import { Invites, invitesModel } from '@cloudstorage/models/invites';
import type { ZodError } from 'zod';
import { request, parseResponse } from '@webapp/services/base';
import type { RequestError } from '@webapp/services/base';

export interface FetchInvitesError {
  message?: string;
}

export async function fetchInvites(): Promise<
  Result<Invites, RequestError | ZodError>
> {
  const response = await request('/api/organization/invitations');

  if (response.isOk) {
    return parseResponse(response, invitesModel);
  }

  return Result.err<Invites, RequestError>(response.error);
}
