/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { type UserExtras } from '@cloudstorage/models/userExtras';
import { useSelector } from 'react-redux';
import {
  loadCurrentUserExtras as loadCurrentUserExtrasAPI,
} from '@cloudstorage/services/userExtras';
import type { RootState } from '@webapp/redux/store';

interface UserExtraRootState {
  type: 'loading' | 'loaded' | 'failed';
  data?: UserExtras;
}

// Define the initial state using that type
const initialState: UserExtraRootState = {
  type: 'loading',
  data: undefined,
};

export const loadCurrentUserExtras = createAsyncThunk(
  'users/loadCurrentUserExtras',
  async () => {
    const res = await loadCurrentUserExtrasAPI();
    if (res.isOk) {
      return Promise.resolve(res.value);
    }

    // Suppress 401 error on login screen
    // TODO(petethepig): we need a better way of handling this exception
    if ('code' in res.error && res.error.code === 401) {
      return Promise.reject(res.error);
    }

    return Promise.reject(res.error);
  }
);


const userExtrasSlice = createSlice({
  name: 'userExtras',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCurrentUserExtras.fulfilled, (state, action) => {
      return { type: 'loaded', data: action.payload };
    });
    builder.addCase(loadCurrentUserExtras.pending, (state) => {
      return { type: 'loading', data: state.data };
    });
    builder.addCase(loadCurrentUserExtras.rejected, (state) => {
      return { type: 'failed', data: state.data };
    });
  },
});


export const currentUserExtrasState = (state: RootState) => state.userExtras;
export const selectCurrentUserExtras = (state: RootState) => state.userExtras?.data;

export const useCurrentUser = () => useSelector(selectCurrentUserExtras);

export default userExtrasSlice.reducer;
