const START_YEAR = '2020';

function copyrightYears(start: string, end: string) {
  return start === end ? start : `${start} – ${end}`;
}

function Footer() {
  const version = process.env.REACT_APP_VERSION;

  return (
    <div className="footer">
      <span title={version}>
        {`© Pyroscope ${copyrightYears(
          START_YEAR,
          new Date().getFullYear().toFixed()
        )}`}
      </span>
    </div>
  );
}

export default Footer;
