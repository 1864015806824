import { formatRelative } from 'date-fns';

import type { Invites } from '@cloudstorage/models/invites';
import type { BodyRow } from '@webapp/ui/Table';

export function getInviteTableRows(displayInvites: Invites): BodyRow[] {
  const bodyRows = displayInvites.reduce((acc, invite) => {
    const { inviter, invitee, createdAt, expiresAt } = invite;
    const row = {
      isRowDisabled: false,
      cells: [
        { value: invitee },
        { value: inviter },
        {
          value: (
            <span title={`${new Date(createdAt)}`}>
              {formatRelative(new Date(createdAt), new Date())}
            </span>
          ),
        },
        {
          value: (
            <span title={`${new Date(expiresAt)}`}>
              {formatRelative(new Date(expiresAt), new Date())}
            </span>
          ),
        },
      ],
    };

    acc.push(row);
    return acc;
  }, [] as BodyRow[]);

  return bodyRows;
}
