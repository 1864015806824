import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from '@bugsnag/plugin-react';

// TODO: get it from env vars
const BUGSNAG_API_KEY = '87cc80bdce131ca459f299c970d63035';

function noopErrorBoundary({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

export function startBugsnag() {
  if (!process.env.REACT_APP_ENABLE_BUGSNAG) {
    // Note that this is not a true error boundary
    return noopErrorBoundary;
  }

  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    releaseStage: process.env.NODE_ENV,
    plugins: [new BugsnagPluginReact()],
    autoDetectErrors: process.env.NODE_ENV !== 'development', // this makes it so that we only report errors in production
    enabledReleaseStages: ['production', 'staging'],
  });

  const plugin = Bugsnag.getPlugin('react');
  const ErrorBoundary = plugin?.createErrorBoundary(
    React
  ) as BugsnagErrorBoundary;
  return ErrorBoundary;
}
