import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faSlack } from '@fortawesome/free-brands-svg-icons/faSlack';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { MenuButton } from '@szhsin/react-menu';
import Dropdown, { MenuItem as DropdownMenuItem } from '@webapp/ui/Dropdown';
import Icon from '@webapp/ui/Icon';
import { MenuItem } from '@webapp/ui/Sidebar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './SupportButton.module.css';

export default function SupportButton() {
  return (
    <>
      <Dropdown
        offsetX={10}
        offsetY={5}
        direction="top"
        label="Support"
        className={styles.dropdown}
        menuButton={
          <MenuButton className={styles.accountDropdown}>
            <MenuItem icon={<Icon icon={faQuestion} />}>Support</MenuItem>
          </MenuButton>
        }
      >
        <DropdownMenuItem
          onClick={() => {
            window.open('https://pyroscope.io/docs', '_blank');
          }}
        >
          <Icon icon={faBook} />
          &nbsp;&nbsp;Documentation&nbsp;&nbsp;
          <FontAwesomeIcon size="xs" icon={faExternalLinkAlt} />
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            window.open('https://pyroscope.io/slack', '_blank');
          }}
        >
          <Icon icon={faSlack} />
          &nbsp;&nbsp;Slack&nbsp;&nbsp;
          <FontAwesomeIcon size="xs" icon={faExternalLinkAlt} />
        </DropdownMenuItem>
      </Dropdown>
    </>
  );
}
