import './App.css';
import { Router, Route, Switch } from 'react-router-dom';
import ContinuousSingleView from '@webapp/pages/ContinuousSingleView';
import TracingSingleView from '@webapp/pages/TracingSingleView';
import ExemplarsSingleView from '@webapp/pages/exemplars/ExemplarsSingleView';
import AdhocSingleView from '@webapp/pages/adhoc/AdhocSingle';
import TagExplorerView from '@webapp/pages/TagExplorerView';
import ContinuousComparisonView from '@webapp/pages/ContinuousComparisonView';
import AdhocComparisonView from '@webapp/pages/adhoc/AdhocComparison';
import ContinuousDiffView from '@webapp/pages/ContinuousDiffView';
import AdhocDiffView from '@webapp/pages/adhoc/AdhocDiff';
import history from '@webapp/util/history';
import { Provider } from 'react-redux';
import Notifications from '@webapp/ui/Notifications';
import { PersistGate } from 'redux-persist/integration/react';
import PageTitle, { AppNameContext } from '@webapp/components/PageTitle';

import { PAGES } from '@webapp/pages/constants';
import store, { persistor } from './redux/store';
import Telemetry from './components/Telemetry';
import Protected from './components/Protected';
import Settings from './components/Settings';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';

// Kinda ugly, but since it's such a unique case it should not matter
import '@webapp/../sass/profile.scss';
import '@szhsin/react-menu/dist/index.css';
import OrganizationWall from './components/OrganizationWall';
import NoDataWall from './components/NoDataWall';
import TermsWall from './components/TermsWall';
import FeatureFlags from './components/FeatureFlags';
import ThrottlingNotifications from './components/ThrottlingNotifications';
import { CIPage } from './pages/Ci';
import { startBugsnag } from './bugsnag';
import { usePosthog } from './posthog';

const ErrorBoundary = startBugsnag();

function App() {
  usePosthog();
  // We nest Notifications under Protected so that
  // we don't dispatch a notification when the user is first loaded
  // This is a workaround and the better solution is to move the notification creation from the thunk to the component (ie the caller)

  return (
    <ErrorBoundary>
      <Protected>
        <OrganizationWall>
          <FeatureFlags>
            <TermsWall>
              <Notifications />
              <ThrottlingNotifications />
              <div className="app">
                <Sidebar />
                <div className="pyroscope-app">
                  <Switch>
                    <Route exact path="/">
                      <NoDataWall>
                        <ContinuousSingleView />
                        <Telemetry />
                      </NoDataWall>
                    </Route>
                    <Route path="/explore">
                      <NoDataWall>
                        <TagExplorerView />
                      </NoDataWall>
                    </Route>
                    <Route path="/comparison">
                      <NoDataWall>
                        <ContinuousComparisonView />
                      </NoDataWall>
                    </Route>
                    <Route path="/comparison-diff">
                      <NoDataWall>
                        <ContinuousDiffView />
                      </NoDataWall>
                    </Route>

                    <Route path={PAGES.ADHOC_SINGLE}>
                      <PageTitle title="Adhoc Single" />
                      <AdhocSingleView />
                    </Route>
                    <Route path={PAGES.ADHOC_COMPARISON}>
                      <PageTitle title="Adhoc Comparison" />
                      <AdhocComparisonView />
                    </Route>
                    <Route path={PAGES.ADHOC_COMPARISON_DIFF}>
                      <PageTitle title="Adhoc Diff" />
                      <AdhocDiffView />
                    </Route>
                    <Route path="/tracing">
                      <TracingSingleView />
                    </Route>
                    <Route path="/settings">
                      <Settings />
                    </Route>
                    <Route path={PAGES.TRACING_EXEMPLARS_SINGLE}>
                      <ExemplarsSingleView />
                    </Route>
                    <Route exact path="/ci">
                      <CIPage />
                    </Route>
                  </Switch>
                  <Footer />
                </div>
              </div>
            </TermsWall>
          </FeatureFlags>
        </OrganizationWall>
      </Protected>
    </ErrorBoundary>
  );
}

export default function Root() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router history={history}>
          <AppNameContext.Provider value="Pyroscope Cloud">
            <App />
          </AppNameContext.Provider>
        </Router>
      </PersistGate>
    </Provider>
  );
}
