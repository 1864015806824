import Modal from './Modal';
import NewOrganizationForm from './NewOrganizationForm';

export default function NewOrganizationModal({
  closable,
  onClose,
}: {
  closable?: boolean;
  onClose?: () => void;
}) {
  return (
    <Modal title="Create an organization" closable={closable} onClose={onClose}>
      <NewOrganizationForm />
    </Modal>
  );
}
