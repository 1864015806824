// TODO: refactor that ternary block
/* eslint-disable no-nested-ternary */

/* eslint-disable import/first */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'react-dom';
import jquery from 'jquery';
import clsx from 'clsx';

// https://github.com/facebook/create-react-app/issues/4281
window.jQuery = jquery;
window.$ = jquery;

import ReactFlot from 'react-flot';
import { useEffect, useState } from 'react';
import { LoadingOverlay } from '@webapp/ui/LoadingOverlay';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { selectCurrentOrganization } from '../redux/reducers/organization';
import styles from './Usage.module.css';
import { type Usage } from '../models/usage';
import { loadUsage } from '../services/usage';

function formatNumber(numb: number, digits?: number) {
  const str = (numb || 0).toFixed(digits).split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
}

function formatEvents(numb: number, digits?: number) {
  if (numb === 1) {
    return `${formatNumber(numb, digits)} event`;
  }
  return `${formatNumber(numb, digits)} events`;
}

const suffixes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
function formatBytes(numb: number, digits?: number) {
  let v = numb;
  digits = 0;
  for (let i = 0; i < suffixes.length; i += 1) {
    if (v < 1024) {
      return `${formatNumber(v, digits)} ${suffixes[i]}`;
    }
    digits = 2;
    v /= 1024;
  }
  return 'n/a';
}

type series = {
  data: [number, number];
};

export default function UsageComponent() {
  const currentOrg = useAppSelector(selectCurrentOrganization);

  const usageBytes = currentOrg?.throttlingPlan.usingBytes;
  const usageMonthlyLimit = currentOrg?.throttlingPlan.limit;

  const [usage, setUsage] = useState({} as Usage);
  const [usageLoaded, setUsageLoaded] = useState(false);

  useEffect(() => {
    async function run() {
      const x = await loadUsage();
      if (x.isOk) {
        setUsageLoaded(true);
        setUsage(x.value);
      }
    }

    void run();
  }, []);

  const formatter = usageBytes ? formatBytes : formatEvents;

  const options = {
    xaxis: {
      mode: 'time',
      min: usage.startTime,
      max: usage.endTime,
      timeBase: 'milliseconds',
      tickLength: 10,
      gridLines: false,
    },
    yaxis: {
      tickFormatter: (v: number) => {
        return formatter(v, 0);
      },
    },
  };

  const todayTime = usage.today;
  const yesterdayTime = usage.today - 86400000;
  let todayTotal = 0;
  let yesterdayTotal = 0;

  (usage.usageByApp || []).forEach((app) => {
    app.datapoints.forEach((d) => {
      if (d[0] === yesterdayTime) {
        yesterdayTotal += d[1];
      } else if (d[0] === todayTime) {
        todayTotal += d[1];
      }
    });
  });

  const byAppOptions = {
    ...options,
    legend: {
      show: true,
      container: '#age-by-app-char-legend',
      labelFormatter: (label: string, series: series) => {
        const { data } = series;
        let yesterday = 0;
        let today = 0;
        data.forEach((d) => {
          if (d[0] === yesterdayTime) {
            yesterday += d[1];
          } else if (d[0] === todayTime) {
            today += d[1];
          }
        });

        return `<span><span>${label}</span><span>${formatter(
          yesterday
        )}</span><span>${formatter(today)}</span></span>`;
      },
    },
  };

  const percentage = usageLoaded
    ? (100 * usage.eventsTotal) / usageMonthlyLimit
    : 0;

  const alreadyOver = usage.eventsTotal > usageMonthlyLimit;
  const willBeOver = usage.estimatedUsage > usageMonthlyLimit;

  return (
    <div className={clsx('', { [styles.blurUsage]: !usageLoaded })}>
      <h2>Monthly Usage</h2>
      <LoadingOverlay spinnerPosition="top" active={!usageLoaded}>
        <p>
          Your organization used
          <strong>&nbsp;{formatter(usage.eventsTotal)}&nbsp;</strong>(
          <span>{formatNumber(percentage, 2)}%</span>) of your
          <strong>&nbsp;{formatter(usageMonthlyLimit)}&nbsp;</strong>
          monthly quota for this month (<span>{usage.reportingPeriod}</span>
          ).
        </p>
        <div className={styles.progressBarWrapper}>
          <div
            className={styles.progressBar}
            style={{
              width: `${Math.min(100, Math.max(1, percentage))}%`,
            }}
          />
        </div>
        <p>
          Your projected usage for this month is:{' '}
          <strong>{formatter(usage.estimatedUsage)}</strong>.&nbsp;
          {alreadyOver ? 'You have exceeded your monthly quota. ' : null}
          {alreadyOver || willBeOver ? (
            <>
              Consider{' '}
              <Link className={styles.link} to="/settings/billing">
                upgrading your plan
              </Link>
            </>
          ) : null}
        </p>
        <h2>Daily Usage</h2>
        <p>Last 60 Days. This chart is updated roughly every 5 minutes.</p>
        <ReactFlot
          id="usage-chart"
          options={options}
          data={[
            {
              data: usage.datapoints,
              bars: { show: true },
            },
          ]}
        />
        <h2>Daily Usage By App</h2>
        <p>
          Last 60 Days. Top 10 apps are reported. This chart is updated roughly
          every 5 minutes.
        </p>
        <ReactFlot
          id="usage-by-app-chart"
          options={byAppOptions}
          data={(usage?.usageByApp || []).map((x) => {
            return {
              label: x.appName,
              data: x.datapoints,
              lines: { show: true },
              points: { show: true },
            };
          })}
        />
        <div className={styles.legend}>
          <table>
            <thead>
              <tr>
                <th />
                <th>
                  <span>
                    <span>App Name</span>
                    <span>Yesterday</span>
                    <span>Today</span>
                  </span>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div id="age-by-app-char-legend" className={styles.legend} />
        <div className={styles.legend}>
          <table>
            <tbody>
              <tr>
                <td />
                <td>
                  <span>
                    <span>
                      <strong>Total</strong>
                    </span>
                    <span>
                      <strong>{formatter(yesterdayTotal)}</strong>
                    </span>
                    <span>
                      <strong>{formatter(todayTotal)}</strong>
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </LoadingOverlay>
    </div>
  );
}
