import LoadingSpinner from '@webapp/ui/LoadingSpinner';
import styles from './FullPageSpinner.module.css';

export default function FullPageSpinner() {
  return (
    <div className={styles.loadingSpinner}>
      <LoadingSpinner />
    </div>
  );
}
