import {
  selectCurrentUser,
  loadCurrentUser,
} from '@webapp/redux/reducers/user';
import { useEffect } from 'react';
import { RequestError } from '@webapp/services/base';
import posthog from 'posthog-js';
import { selectCurrentOrganization } from '@cloudstorage/redux/reducers/organization';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import FullPageSpinner from './FullPageSpinner';
import { redirectToLogin } from '../services/login';

export default function Protected({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const org = useAppSelector(selectCurrentOrganization);

  const productionMode = process.env.NODE_ENV === 'production';
  // const productionMode = true;

  useEffect(() => {
    if (productionMode && user && org) {
      posthog.identify(user?.email, {
        userEmail: user?.email,
        orgId: org.id,
        orgName: org.name,
        orgDisplayName: org.displayName,
      });
    }
  }, [user, org]);

  useEffect(() => {
    async function loadUser() {
      try {
        await dispatch(loadCurrentUser()).unwrap();
      } catch (e) {
        const err = e as RequestError;
        if ('code' in err) {
          if (err.code === 403) {
            redirectToLogin();
          }
        }
        // TODO(petethepig): not sure what's the right course of action here
        //   maybe in dev env we should handle it differently
        throw e;
      }
    }

    if (!user) {
      void loadUser();
    }
  }, [user, dispatch]);

  if (!user) {
    return <FullPageSpinner />;
  }

  return <>{children}</>;
}
