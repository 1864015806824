// TODO: refactor that ternary block
/* eslint-disable no-nested-ternary */

/* eslint-disable import/first */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import 'react-dom';
import jquery from 'jquery';

// https://github.com/facebook/create-react-app/issues/4281
window.jQuery = jquery;
window.$ = jquery;

import Button from '@webapp/ui/Button';
import Box from '@webapp/ui/Box';
import { useEffect, useState } from 'react';
import { selectPlans } from '@cloudstorage/redux/reducers/plans';
import { Plan } from '@cloudstorage/models/plans';
import { loadCurrentOrganization } from '@cloudstorage/redux/reducers/organization';
import { addNotification } from '@webapp/redux/reducers/notifications';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { selectCurrentOrganization } from '../redux/reducers/organization';
import styles from './Billing.module.css';
import {
  loadPayments,
  goToStripeManage,
  goToStripeSetup,
} from '../services/payments';
import { loadPlans } from '../redux/reducers/plans';
import { switchPlan } from '../services/plans';

function formatNumber(numb: number, digits?: number) {
  const str = (numb || 0).toFixed(digits).split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
}

function formatEvents(numb: number, digits?: number) {
  if (numb === 1) {
    return `${formatNumber(numb, digits)} event`;
  }
  return `${formatNumber(numb, digits)} events`;
}

function formatDollars(numb: number, digits?: number) {
  return `$${formatNumber(numb, digits)}`;
}

const suffixes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
function formatBytes(numb: number, digits?: number) {
  let v = numb;
  digits = 0;
  for (let i = 0; i < suffixes.length; i += 1) {
    if (v < 1024) {
      return `${formatNumber(v, digits)} ${suffixes[i]}`;
    }
    digits = 2;
    v /= 1024;
  }
  return 'n/a';
}

function PlanComponent({
  plan,
  switchPlanHandler,
  upgrade,
  requiresCard,
}: {
  plan: Plan;
  switchPlanHandler: () => void;
  upgrade: boolean;
  requiresCard: boolean;
}) {
  return (
    <>
      <div className={styles.planInfo}>
        <div className={styles.planHeader}>
          <h3 className={styles.planName}>{plan?.name}</h3>
          <div className={styles.planButtons}>
            {switchPlanHandler && (
              <>
                {requiresCard ? (
                  <Button onClick={() => goToStripeSetup()} kind="secondary">
                    Add credit card
                  </Button>
                ) : (
                  <Button
                    kind="secondary"
                    onClick={() => switchPlanHandler(plan)}
                  >
                    {upgrade ? 'Upgrade' : 'Downgrade'} your plan
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <ul>
          <li>
            Monthly Ingestion Limit:{' '}
            {plan?.usingBytes
              ? formatBytes(plan?.limit)
              : formatEvents(plan?.limit)}
          </li>
          {/* TODO: get these from the plan */}
          <li>Retention: {plan?.retention} days</li>
          <li>Price: {formatDollars(plan?.price)} / month</li>
        </ul>
      </div>
    </>
  );
}

export default function BillingComponent() {
  const currentOrg = useAppSelector(selectCurrentOrganization);

  const usageShowCreditCardButton = !currentOrg?.hideCreditCardButton;

  const [hasValidCard, setHasValidCard] = useState(false);
  const [paymentsLoaded, setPaymentsLoaded] = useState(false);

  useEffect(() => {
    async function run() {
      const x = await loadPayments();

      if (x.isOk) {
        setPaymentsLoaded(true);
        setHasValidCard(x.value.hasValidCard);
      }
    }

    void run();
  }, []);

  const dispatch = useAppDispatch();
  const plans = useAppSelector(selectPlans) as Plan[];

  const switchPlanHandler = async (newPlan: Plan) => {
    const res = await switchPlan(newPlan);
    async function load() {
      await dispatch(loadCurrentOrganization()).unwrap();
    }
    if (res.isOk) {
      // document.location.href = '/settings/billing';
      void load();
    } else {
      void dispatch(
        addNotification({
          type: 'danger',
          title: 'Failed to switch plans',
          message: res.error.message,
        })
      );
    }
  };

  const cancelSubscription = () => {
    const freePlan = plans.find((p) => p.price === 0);
    void switchPlanHandler(freePlan);
  };

  useEffect(() => {
    async function load() {
      await dispatch(loadPlans()).unwrap();
    }

    if (!plans) {
      void load();
    }
  }, [plans, dispatch]);

  const currentPlan = currentOrg?.throttlingPlan;

  return (
    <div className="">
      <h2>Current Plan</h2>
      <Box className={styles.planBox}>
        <PlanComponent plan={currentPlan} />
      </Box>

      {usageShowCreditCardButton ? (
        <>
          <hr className={styles.hr} />
          <h2 className={styles.sectionHeader}>Switch Plan</h2>
          <>
            {(plans || []).map((plan) => {
              if (plan.id === currentPlan?.id) {
                return null;
              }
              return (
                <Box key={plan.id} className={styles.planBox}>
                  <PlanComponent
                    plan={plan}
                    switchPlanHandler={(plan) => {
                      void switchPlanHandler(plan);
                    }}
                    upgrade={currentPlan.price < plan.price}
                    requiresCard={!hasValidCard && plan.price !== 0}
                  />
                </Box>
              );
            })}
          </>
          <hr className={styles.hr} />
          <h2 className={styles.sectionHeader}>
            Manage Credit Cards and Invoices <small>(via Stripe)</small>
          </h2>
          <p>
            {paymentsLoaded ? (
              hasValidCard ? (
                <>
                  <p>
                    Click button below to update your credit card information.
                  </p>
                  <Button onClick={() => goToStripeManage()} kind="secondary">
                    Manage subscription
                  </Button>{' '}
                  (or get invoices)
                </>
              ) : (
                <>
                  <p>
                    We do not have your credit card on file. Click button below
                    to add a credit card.
                  </p>
                  <Button onClick={() => goToStripeSetup()} kind="secondary">
                    Add credit card
                  </Button>
                </>
              )
            ) : null}
          </p>
          <hr className={styles.hr} />
          <h2 className={styles.sectionHeader}>Cancel Subscription</h2>
          <p>
            <Button kind="danger" onClick={cancelSubscription}>
              Cancel Subscription
            </Button>
          </p>
        </>
      ) : (
        <>
          <h2>Switch Plan</h2>
          <p>
            Please email us at{' '}
            <a className={styles.link} href="mailto:contact@pyroscope.io">
              contact@pyroscope.io
            </a>{' '}
            to change your plan.
          </p>
        </>
      )}
    </div>
  );
}
