import { Result } from '@webapp/util/fp';
import type { ZodError } from 'zod';
import { request, parseResponse } from '@webapp/services/base';
import type { RequestError } from '@webapp/services/base';
import { type Usage, usageModel } from '../models/usage';

export async function loadUsage(): Promise<
  Result<Usage, RequestError | ZodError>
> {
  const response = await request(`/api/usage`);
  if (response.isOk) {
    return parseResponse(response, usageModel);
  }

  return Result.err<Usage, RequestError>(response.error);
}
