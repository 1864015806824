import { Result } from '@webapp/util/fp';
import type { ZodError } from 'zod';
import { request, parseResponse } from '@webapp/services/base';
import type { RequestError } from '@webapp/services/base';
import {
  Application,
  applicationSchema,
  Branch,
  branchSchema,
  Event,
  eventSchema,
  EventProfile,
  eventProfileSchema,
} from '@cloudstorage/models/ci';

export async function loadApplications(): Promise<
  Result<Application[], RequestError | ZodError>
> {
  const response = await request('/api/ci-events/applications');

  if (response.isOk) {
    return parseResponse(response, applicationSchema.array());
  }

  return Result.err(response.error);
}

export async function loadBranches(
  appName: string
): Promise<Result<Branch[], RequestError | ZodError>> {
  const response = await request(
    `/api/ci-events/applications/${encodeURIComponent(appName)}/branches`
  );

  if (response.isOk) {
    return parseResponse(response, branchSchema.array());
  }

  return Result.err(response.error);
}

export async function loadEvents(
  appName: string,
  branch: string,
  from: Date,
  until: Date
): Promise<Result<Event[], RequestError | ZodError>> {
  const fromInUnix = Math.floor(from.getTime() / 1000);
  const untilInUnix = Math.floor(until.getTime() / 1000);

  // TODO: use URLSearchParams
  const response = await request(
    `/api/ci-events/applications/${encodeURIComponent(
      appName
    )}/branches/${encodeURIComponent(
      branch
    )}/events?from=${fromInUnix}&until=${untilInUnix}`
  );

  if (response.isOk) {
    return parseResponse(response, eventSchema.array());
  }

  return Result.err(response.error);
}

export async function loadEventProfile(
  id: string,
  appName: string
): Promise<Result<EventProfile, RequestError | ZodError>> {
  const response = await request(
    `/api/ci-events/applications/${encodeURIComponent(appName)}/events/${id}`
  );

  if (response.isOk) {
    return parseResponse(response, eventProfileSchema);
  }

  return Result.err(response.error);
}
