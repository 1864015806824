import { z } from 'zod';

export const usageModel = z.object({
  eventsTotal: z.number(),
  datapoints: z.array(z.tuple([z.number(), z.number()])),
  startTime: z.number(),
  endTime: z.number(),
  reportingPeriod: z.string(),
  today: z.number(),
  estimatedUsage: z.number(),
  usageByApp: z.array(
    z.object({
      appName: z.string(),
      datapoints: z.array(z.tuple([z.number(), z.number()])),
    })
  ),
});

export type Usage = z.infer<typeof usageModel>;
